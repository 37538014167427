import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createCourse, updateCourse } from './passiveCourseThunks';
import defaultState from 'store/defaultState';
import { CourseApi } from 'types/backend/courses.types';
import { ProductApi } from 'types/backend/products.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { TopicApi } from 'types/backend/topics.types';
import { UnitApi } from 'types/backend/units.types';
import { UserApi } from 'types/backend/users.types';

export interface Passive {
  instructorCourses: Array<CourseApi>
  studentCourses: Array<CourseApi>
  coinstructorsFromEnrolledCourses: Array<UserApi>
  subjects: Array<SubjectApi>
  units: Array<UnitApi>
  topics: Array<TopicApi>
  products: Array<ProductApi>
}

const passiveSlice = createSlice({
  name: 'passive',
  initialState: defaultState.passive,
  reducers: {
    appendNewInstructorCourse: (passive, action: PayloadAction<CourseApi>) => {
      return {
        ...passive,
        instructorCourses: [
          ...passive.instructorCourses,
          action.payload,
        ],
      };
    },
    updatePassiveInstructorCourse: (passive, action: PayloadAction<CourseApi>) => {
      return {
        ...passive,
        instructorCourses: passive.instructorCourses.map(course => {
          return course.id === action.payload.id
            ? action.payload
            : course;
        }),
      };
    },
    setPassiveData: (passive, action: PayloadAction<Passive>) => {
      return action.payload;
    },
    clear: () => {
      return defaultState.passive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCourse.fulfilled, (passive, action) => {
      return {
        ...passive,
        instructorCourses: passive.instructorCourses.map(course => {
          return course.id === action.payload.id
            ? action.payload
            : course;
        }),
      };
    });
    builder.addCase(createCourse.fulfilled, (passive, action) => {
      return {
        ...passive,
        instructorCourses: [
          ...passive.instructorCourses,
          action.payload,
        ],
      };
    });
  },
});

export default passiveSlice;

import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import apiNext from 'api-next';
import { InstitutionApi } from 'types/backend/institutions.types';
const apiUrl = process.env.REACT_APP_API_NEXT_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = apiNext.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (apiNext.masqueradeUserId) {
      headers.set('x-masquerade-as', apiNext.masqueradeUserId);
    }
    return headers;
  },
});

export const baseApi = createApi({
  baseQuery: retry(baseQuery, { maxRetries: 5 }),
  endpoints: (build) => ({
    getInstitutions: build.query<Array<InstitutionApi>, void>({
      query: () => ({ url: '/institutions?$sort[name]=1' }),
      transformResponse: (response: { data: Array<InstitutionApi> }, meta, arg) => response.data,
    }),
  }),
});

export const { useGetInstitutionsQuery } = baseApi;

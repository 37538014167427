import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import StudentAssessmentLink from 'student/components/StudentAssessmentLink/StudentAssessmentLink';

import { determineMultipleAttemptPolicy } from 'utils/assessmentFunctions';
import { formatPoints, renderRecap } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import retrieveEnrichedStudentAssessment, { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Instructions from 'student/controllers/Course/AssessmentTakerController/components/Instructions';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { YesNo } from 'types/backend/shared.types';
import { PositionEnum } from 'types/common.types';
import './AssignmentListContainer.scss';

export default function AssignmentListContainer() {
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessment);
  // do not include Summative assessments in list
  const filteredStudentAssessments = enrichedStudentAssessments.filter(({ assessType }) => assessType !== AssessTypeEnum.Summative);

  function formatOpenDate(enrichedStudentAssessment: EnrichedStudentAssessment) {
    if ([AssessTypeEnum.Prep, AssessTypeEnum.PracticeTest].includes(enrichedStudentAssessment.assessType)) {
      return (
        <BetterTooltip
          content={enrichedStudentAssessment.assessType === AssessTypeEnum.Prep ?
            "You can start a topic's Prep Questions once you move that topic into Checkpoint 2. This cannot happen until you finish a Homework or Pre-class assessment covering that topic."
            : "You can start the Practice Test once you've completed all assignments associated with this unit and moved all topics into Checkpoint 3."
          }
          indicate
        />
      );
    }
    const { mergedOpenDate } = enrichedStudentAssessment;
    return DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear);
  }

  function determineStatus(enrichedStudentAssessment: EnrichedStudentAssessment) {
    const { mergedDueDate, assessmentStatus } = enrichedStudentAssessment;
    switch (assessmentStatus) {
      case StudentAssessmentStatus.NotStartedAfterLate:
      case StudentAssessmentStatus.InProgressAfterLate:
        return `${DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}`;
      case StudentAssessmentStatus.CompletedBeforeDue:
      case StudentAssessmentStatus.CompletedBeforeLate:
      case StudentAssessmentStatus.CompletedAfterLate:
        return (
          <BetterTooltip
            content={`Due Date: ${DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}`}
            position={PositionEnum.Bottom}
          >
            <span className="completed-on-time-badge-sm">COMPLETE</span>
          </BetterTooltip>
        );

      default:
        return DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear);
    }
  }

  function determinePoints(enrichedStudentAssessment: EnrichedStudentAssessment) {
    let pointDisplay = '';
    if (enrichedStudentAssessment.gradingPolicy === GradingPolicyEnum.NoPoints) {
      pointDisplay = '--';
    } else if (enrichedStudentAssessment.assessType !== AssessTypeEnum.Summative) {
      pointDisplay = `${enrichedStudentAssessment.hasBeenStarted || !enrichedStudentAssessment.isBeforeDue ? formatPoints(enrichedStudentAssessment.pointsEarned, true) : '-'} / ${formatPoints(enrichedStudentAssessment.totalPoints, true)} pts`;
      switch (enrichedStudentAssessment.assessmentStatus) {
        case StudentAssessmentStatus.NotStartedBeforeLate:
        case StudentAssessmentStatus.InProgressBeforeLate:
        case StudentAssessmentStatus.CompletedBeforeLate:
          pointDisplay += ' (late)';
          break;
        case StudentAssessmentStatus.NotStartedAfterLate:
        case StudentAssessmentStatus.InProgressAfterLate:
          pointDisplay += ' (incomplete)';
          break;
        case StudentAssessmentStatus.CompletedAfterLate:
          pointDisplay += ' (after late date)';
          break;
        default:
          break;
      }
    }
    return pointDisplay;
  }

  return (
    <main className="assignment-container">
      <h2 className="h3 assignment-container__heading">
        Assignments
      </h2>
      <table className="assignments-table">
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Open Date
            </th>
            <th>
              Due Date
            </th>
            <th aria-hidden="true">
              &nbsp;
            </th>
            <th>
              Points
              <BetterTooltip
                indicate
                className='instruction__points-tooltip'
                position={PositionEnum.Left}
                content="Hover over the points for each assignment below to see detailed grading policy."
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="first-row"/>
          {filteredStudentAssessments.map((enrichedStudentAssessment: EnrichedStudentAssessment, index: number) => {
            const {
              assessType,
              id: assessmentId,
              isAfterLate,
              openToStudents,
              published,
            } = enrichedStudentAssessment;
            return (
              <Fragment key={`frag-esa-id-${assessmentId}_${enrichedStudentAssessment.name}`}>
                <tr className="assignment-container__table-row" data-isopen={openToStudents} data-ispastdue={isAfterLate}>
                  <td className="assignments-table__assessment-name">
                    <StudentAssessmentLink
                      key={`assessment-link_${assessmentId}`}
                      enrichedStudentAssessment={enrichedStudentAssessment}
                    />
                  </td>
                  <td>
                    {formatOpenDate(enrichedStudentAssessment)}
                  </td>
                  <td className="assignment-list__due-date">
                    {determineStatus(enrichedStudentAssessment)}
                  </td>
                  <td className="icon">
                    {renderRecap(enrichedStudentAssessment)}
                  </td>
                  <td>
                    {!(assessType === AssessTypeEnum.PracticeTest && published === YesNo.No) && (
                      <BetterTooltip
                        key={`instructions${assessmentId}`}
                        position={PositionEnum.Left}
                        className='instruction__assignment-points-policy-tooltip'
                        content={() => (
                          <div className='instruction__assignment-points-policy-tooltip-content'>
                            <Instructions
                              assessmentData={enrichedStudentAssessment}
                              attemptPolicy={determineMultipleAttemptPolicy(
                                enrichedStudentAssessment.freeAttempts,
                                enrichedStudentAssessment.pointPenalty,
                                enrichedStudentAssessment.gradingPolicy
                              )}
                            />
                          </div>
                        )}
                      >
                        {determinePoints(enrichedStudentAssessment)}
                      </BetterTooltip>
                    )}
                  </td>
                </tr>
                {assessType === AssessTypeEnum.PracticeTest && index < filteredStudentAssessments.length - 1 && (
                  <>
                    <tr className="first-row"/>
                    <tr className='spacer-row'>
                      <td colSpan={5}></td>
                    </tr>
                  </>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </main>
  );
}

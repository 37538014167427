import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import apiNext from 'api-next';
import useEffectOnce from 'hooks/useEffectOnce';
import { useAppDispatch } from 'store';
import addLoToClassSession from 'store/actions/addLoToClassSession';
import CourseQACheck from 'shared-components/CourseQACheck/CourseQACheck';
import AddLoConfirmation from 'shared-components/CourseQACheck/AddLoConfirmation';
import { Store } from 'types/store.types';
import { CourseHealthCheckResponseMulti } from 'types/backend/courseHealthCheck.types';
import './SingleQADashboard.scss';

export interface AddLoConfirmationData {
  loId: number
  title: string
  topicId: number
  topicName: string
}

export default function SingleQADashboardController() {
  const dispatch = useAppDispatch();
  const course = useSelector((store: Store) => store.active.course);
  const { id: courseId } = course;
  const adminUser = useSelector((store: Store) => store.adminUser);
  const [qaResults, setQAResults] = useState<CourseHealthCheckResponseMulti>();
  const [addLoConfirmationData, setAddLoConfirmationData] = useState<AddLoConfirmationData | null>(null);


  async function getQAResults() {
    const [ result ] = await apiNext.getCourseHealthChecks([courseId]);
    setQAResults(result);
  }
  useEffectOnce(() => {
    getQAResults();
  });

  const addLoToSession = async (loId: number, classSessionId: number) => {
    await dispatch(addLoToClassSession(loId, classSessionId, true));
    await getQAResults();
    setAddLoConfirmationData(null);
  };


  if (!adminUser) {
    return null;
  }

  const renderQAResults = () => {
    if (!!qaResults) {
      const { qaChecks } = qaResults;
      return (
        <CourseQACheck
          qaCheckData={qaChecks}
          setAddLoConfirmationData={setAddLoConfirmationData}
        />
      );
    }
  };

  return (
    <>
      {!!addLoConfirmationData && (
        <AddLoConfirmation
          handleAddLoToCourse={addLoToSession}
          handleCancel={() => setAddLoConfirmationData(null)}
          loData={addLoConfirmationData}
        />
      )}
      <div className="single-course-qa-dashboard">
        {renderQAResults()}
      </div>
    </>
  );
}

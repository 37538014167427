import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';

type PollingFunction = () => Promise<void>;

export enum PollingMethod {
  Exponential = 'exponential',
  Linear = 'linear'
}

interface PollingContextType {
  startPolling: (pollingFunction: PollingFunction, delay: number, maxIterations?: number, pollingMethod?: PollingMethod) => void
  stopPolling: (localStorageKey?: string) => void
}

const PollingContext = createContext<PollingContextType>({
  startPolling: () => {},
  stopPolling: () => {},
});

export const usePolling = () => useContext(PollingContext);

const MAX_ITERATIONS = 8; // total 4min duration

export const PollingProvider = ({ children }: {children: React.ReactNode}) => {
  const pollingInfoRef = useRef<{
    timeoutId: NodeJS.Timeout | null
    iterationCount: number
    delay: number
    maxIterations: number
    isStopped: boolean
  }>({
    timeoutId: null,
    iterationCount: 0,
    delay: 0,
    maxIterations: MAX_ITERATIONS,
    isStopped: false,
  });

  const startPolling = (pollingFunction: Function, initialDelay: number, maxIterations: number = MAX_ITERATIONS, pollingMethod = PollingMethod.Exponential) => {
    stopPolling();
    pollingInfoRef.current.iterationCount = 0;
    pollingInfoRef.current.delay = initialDelay;
    pollingInfoRef.current.maxIterations = maxIterations;
    pollingInfoRef.current.isStopped = false;

    const executePolling = async () => {
      if (pollingInfoRef.current.isStopped) {
        return;
      }
      await pollingFunction();
      pollingInfoRef.current.iterationCount += 1;

      if (pollingInfoRef.current.iterationCount < pollingInfoRef.current.maxIterations) {
        pollingInfoRef.current.delay = pollingMethod === PollingMethod.Exponential
          ? pollingInfoRef.current.delay * 2
          : pollingInfoRef.current.delay + 2000;
        pollingInfoRef.current.timeoutId = setTimeout(executePolling, pollingInfoRef.current.delay);
      } else {
        stopPolling();
      }
    };

    pollingInfoRef.current.timeoutId = setTimeout(executePolling, pollingInfoRef.current.delay);
  };

  const stopPolling = (localStorageKey?: string) => {
    if (pollingInfoRef.current.timeoutId) {
      clearTimeout(pollingInfoRef.current.timeoutId);
      pollingInfoRef.current.timeoutId = null;
    }

    if (localStorageKey) {
      localStorage.removeItem(localStorageKey);
    }
    pollingInfoRef.current.isStopped = true;
  };

  useEffect(() => {
    return () => stopPolling();
  }, []);

  return (
    <PollingContext.Provider value={{ startPolling, stopPolling }}>
      {children}
    </PollingContext.Provider>
  );
};

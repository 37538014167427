import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FaChevronLeft } from 'react-icons/fa';

import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import apiNext from 'api-next';
import sharedStrings from 'sharedStrings';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { usePolling, PollingMethod } from 'shared-components/Polling/PollingContext';
import { EnrichedAssessmentForStudentScores, StudentScoresViewEnum } from 'instructor/controllers/Course/StudentScoresController/studentScores.types';
import { ConfirmationTypeEnum, PositionEnum } from 'types/common.types';
import { formatPlural, getShortAssessType } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { AssessmentScoreSyncApi, SyncStatus, ProgressResult } from 'types/backend/assessmentScoreSync.types';
import { UserApiIdNameEmail } from 'types/backend/users.types';
import './GradeSync.scss';

interface AssessmentSyncStatusEntry {
  id: string
  name: string
  syncStatus: SyncStatus | null
  startTimestamp: string | null
  submittedScoreQty: number | null
  syncedScoreQty: number | null
  syncFailedUsers?: Array<UserApiIdNameEmail>
  progressResult: ProgressResult | null
}

interface GradeSyncProps {
  assessments: Array<EnrichedAssessmentForStudentScores>
  handleStudentScoresView: (scoresView: StudentScoresViewEnum, selectedId?: number | string) => void
  handleAssessmentSync: (assessmentIds: Array<string>) => Promise<Array<AssessmentScoreSyncApi>>
}

function GradeSync({
  assessments,
  handleStudentScoresView,
  handleAssessmentSync,
}: GradeSyncProps) {
  const [syncing, setSyncing] = useState(false);
  const [checkingSyncStatus, setCheckingSyncStatus] = useState(false);
  const availableAssessments = filterAssessmentsToAvailableAssessments(assessments);
  const [selectableAssessments, setSelectableAssessments] = useState(availableAssessments);
  const [selectedAssessments, setSelectedAssessments] = useState([] as Array<EnrichedAssessmentForStudentScores>);
  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState([] as Array<string>);
  const [syncStatuses, setSyncStatuses] = useState({} as { [key: string]: AssessmentSyncStatusEntry});
  const { startPolling, stopPolling } = usePolling();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const syncJobIds = useRef(new Set<string>());

  function filterAssessmentsToAvailableAssessments(availAssessments: Array<EnrichedAssessmentForStudentScores>) {
    return availAssessments.filter(ax => ax.isGradeSyncEnabled === true);
  }

  const toggleCoveredAssessment = (assessmentId: string) => {
    const updateSelectList = (selectedOrSelectableAssessments: Array<EnrichedAssessmentForStudentScores>) => {
      const newSelectList = availableAssessments.reduce((acc, cur) => {
        const assessmentInSelectList = selectedOrSelectableAssessments.some(ax => ax.id === cur.id);
        return (cur.id !== assessmentId && assessmentInSelectList)
          || (cur.id === assessmentId && !assessmentInSelectList)
          ? [...acc, cur]
          : acc;
      }, [] as Array<EnrichedAssessmentForStudentScores>);
      return newSelectList;
    };
    const newSelectableAssessments = updateSelectList(selectableAssessments);
    const newSelectedAssessments = updateSelectList(selectedAssessments);
    const newSelectedAssessmentIds = newSelectedAssessments.map(({ id }) => id);
    setSelectableAssessments(newSelectableAssessments);
    setSelectedAssessments(newSelectedAssessments);
    setSelectedAssessmentIds(newSelectedAssessmentIds);
  };

  const syncConfirmationMessage = (assessmentCount: number) => {
    return (
      <div>
        You have selected {assessmentCount} {formatPlural('assessment', assessmentCount)} to sync.
        <br/><br/>
        Sync will override existing scores, including ones that you have manually changed in the LMS gradebook.
      </div>
    );
  };

  const syncScores = async (assessmentIdsToSync: Array<string>) => {
    setSyncing(true);
    const syncResults = await handleAssessmentSync(assessmentIdsToSync);
    const jobIds = syncResults.map(syncResult => syncResult.id);
    jobIds.forEach(jobId => syncJobIds.current.add(jobId));
    setCheckingSyncStatus(true);
    setSyncing(false);
  };

  const handleSyncScores = (assessmentIdsToSync: Array<string>) => {
    triggerConfirmationPrompt({
      title: 'Are you sure you want to sync?',
      message: syncConfirmationMessage(assessmentIdsToSync.length),
      confirmButtonText: 'Sync scores now',
      confirmationType: ConfirmationTypeEnum.Warn,
      onConfirm: () => syncScores(assessmentIdsToSync),
      onCancel: () => {return;},
    });
  };

  const checkSyncStatus = async (assessmentIds: Array<string>) => {
    setCheckingSyncStatus(true);
    const newSyncStatuses = { } as { [key: string]: AssessmentSyncStatusEntry};
    for (const id of assessmentIds) {
      const syncResults = await apiNext.getAssessmentScoreSyncResultsByAssessmentId(id);
      const { name } = availableAssessments.find(a => a.id === id) as EnrichedAssessmentForStudentScores;
      let startTimestamp = null;
      let syncStatus = null;
      let submittedScoreQty = null;
      let syncedScoreQty = null;
      let syncFailedUsers = undefined;
      let progressResult = null;
      if (!!syncResults.length) {
        [ { startTimestamp, syncStatus, submittedScoreQty, syncedScoreQty, syncFailedUsers, progressResult } ] = syncResults;
      }
      const newSyncStatusEntry = {
        name,
        id,
        startTimestamp,
        syncStatus,
        submittedScoreQty,
        syncedScoreQty,
        syncFailedUsers,
        progressResult,
      };
      newSyncStatuses[id] = newSyncStatusEntry;
    }
    setSyncStatuses(newSyncStatuses);
    setCheckingSyncStatus(false);
  };

  const getStatusMessage = (stat: AssessmentSyncStatusEntry) => {
    switch (stat.syncStatus) {
      case SyncStatus.Completed:
        return `${stat.syncedScoreQty} of ${stat.submittedScoreQty} scores synced successfully`;
      case SyncStatus.InProgress:
        let progressMsg = '';
        let remainingNum = stat.submittedScoreQty;
        if (stat?.progressResult) {
          const { failedQty = 0, completedQty = 0, submittedScoreQty } = stat.progressResult;
          if (completedQty > 0 || failedQty > 0) {
            const completedPart = completedQty > 0 ? `${completedQty} completed` : '';
            const failedPart = failedQty > 0 ? `${failedQty} failed` : '';
            progressMsg = `, ${[completedPart, failedPart].filter(Boolean).join(', ')}`;
          }

          remainingNum = submittedScoreQty - failedQty - completedQty;
        }
        return `${remainingNum} still in progress${progressMsg}`.trim();
      case SyncStatus.PartiallyFailed:
        let successSyncQty = (stat.submittedScoreQty || 0) - (stat.syncFailedUsers?.length || 0);
        if (successSyncQty < 0) {
          successSyncQty = 0;
        }
        return `${successSyncQty} of ${stat.submittedScoreQty} scores successfully synced.
          ${stat.syncFailedUsers?.length} score(s) failed
          (${stat.syncFailedUsers?.map(({ email, firstName, lastName }) => ` ${firstName} ${lastName} - ${email}`).join(',')}).
          They may have dropped.`;
      case SyncStatus.Failed:
        return `all ${stat.submittedScoreQty} scores failed to sync`;
      case null:
      default:
        return 'this assessment has never been synced';
    }
  };

  const missingTip = (
    <>
      To be eligible for grade sync, assessments must be published and "Enable Grade Sync to LMS" must be turned on.
      <br/>
      <br/>
      For other questions about grade sync, contact your Faculty Success Manager.
    </>
  );

  const displaySyncStatus = useCallback((assessmentId: string, syncResult: AssessmentScoreSyncApi) => {
    const newSyncStatuses = { } as { [key: string]: AssessmentSyncStatusEntry};
    const { name } = availableAssessments.find(a => a.id === assessmentId) as EnrichedAssessmentForStudentScores;
    const { startTimestamp, syncStatus, submittedScoreQty, syncedScoreQty, syncFailedUsers, progressResult } = syncResult;
    const newSyncStatusEntry = {
      id: assessmentId,
      name,
      startTimestamp,
      syncStatus,
      submittedScoreQty,
      syncedScoreQty,
      syncFailedUsers,
      progressResult,
    };
    newSyncStatuses[assessmentId] = newSyncStatusEntry;
    setSyncStatuses(prev => ({ ...prev, ...newSyncStatuses }));
  }, [availableAssessments]);


  const pollingFunction = useCallback(async () => {
    const scoreSyncResultsPromises = Array.from(syncJobIds.current).map(jobId =>
      apiNext.getAssessmentScoreSyncResultsById(jobId)
    );

    const scoreSyncResults = await Promise.all(scoreSyncResultsPromises);
    let isStopPolling = true;

    for (const syncResult of scoreSyncResults) {
      if (!syncResult) {
        continue;
      }
      if (syncResult.syncStatus === SyncStatus.InProgress) {
        isStopPolling = false;
      } else {
        syncJobIds.current.delete(syncResult.id);
      }
      displaySyncStatus(syncResult.assessmentId, syncResult);
    }

    if (isStopPolling) {
      setCheckingSyncStatus(false);
      stopPolling();
    }
  }, [displaySyncStatus, stopPolling]);


  // NOTE: this useEffect initiates polling of grade sync results
  useEffect(() => {
    if (!checkingSyncStatus || !syncJobIds.current.size) {
      return;
    }

    const initialPollingDelay = Number(process.env.REACT_APP_INITIAL_POLLING_DELAY) || 1000;
    const maxIterations = 20; // about 6mins in linear polling
    startPolling(pollingFunction, initialPollingDelay, maxIterations, PollingMethod.Linear);
    return () => {
      if (!checkingSyncStatus) {
        stopPolling();
      }
    };
  // NOTE: had to remove dependencies to avoid retrigerring useffect thus multiplying polling instances
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingSyncStatus]);

  const renderAssessmentList = (assessmentArray: Array<EnrichedAssessmentForStudentScores>, emptyListMessage: string) => {
    if (!assessmentArray.length) {
      return (
        <div className="grade-sync-list__empty">
          {emptyListMessage}
        </div>
      );
    }
    const sortedAssessmentArray = assessmentArray.sort((a, b) => +DateTime.fromISO(b.dueDate) - +DateTime.fromISO(a.dueDate));
    return (
      <ul className="grade-sync-list">
        {sortedAssessmentArray.map(({ id: assessmentId, assessType: priorAssessType, dueDate, name }) => (
          <li key={assessmentId} data-assessmentid={assessmentId} className="grade-sync-list__row">
            <label>
              <div className="grade-sync-list__name">
                <input
                  type="checkbox"
                  checked={selectedAssessmentIds.includes(assessmentId)}
                  value={assessmentId}
                  onChange={() => toggleCoveredAssessment(assessmentId)}
                />
                <span className={`assess-type-pill ${priorAssessType}`}>
                  {getShortAssessType(priorAssessType)}
                </span>
                {name}
              </div>
              <div className="dueDate">
                Due {DateTime.fromISO(dueDate).toFormat(DateFormatEnum.MonthDay)}
              </div>
            </label>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="grade-sync">
      <div className="grade-sync__menubar">
        <BetterButton
          className="score-book-container__title-bar-link"
          onClick={() => handleStudentScoresView(StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW)}
          text="Back"
          icon={() => <FaChevronLeft />}
          secondary
        />
        Sync Grades to LMS
      </div>
      <div className="grade-sync__row row">
        <div className="grade-sync__column grade-sync__column-left col-md-6 col-lg-6 col-xl-6">
          <div className="grade-sync__section">
            <div className="grade-sync__section-header eligible">
              Eligible Assessments
              <span className="missing-info-text">
                <BetterTooltip
                  content={missingTip}
                  position={PositionEnum.Bottom}
                >
                  Missing Something?
                </BetterTooltip>
              </span>
            </div>
            {renderAssessmentList(selectableAssessments, 'No more selectable prior assessments')}
          </div>
        </div>
        <div className="grade-sync__column grade-sync__column-right col-md-6 col-lg-6 col-xl-6">
          <div className="grade-sync__section">
            <div className="grade-sync__section-header">
              Selected Assessments
            </div>
            {renderAssessmentList(selectedAssessments, 'Select eligible assessments from the list on the left')}
          </div>
          <div className="grade-sync__action-bar">
            <div className="grade-sync__action-bar-item">
              {sharedStrings.SYNC_WARNING_MESSAGE}
            </div>
            <LoadingButton
              className="grade-sync__action-bar-button"
              loading={checkingSyncStatus}
              loadingText='Loading Sync Status'
              onClick={() => checkSyncStatus(selectedAssessmentIds)}
              text="Check Sync Status"
              type="button"
              disabled={!selectedAssessments.length}
            />
            <LoadingButton
              className="grade-sync__action-bar-button"
              loading={syncing}
              loadingText='Initiating Grade Sync'
              onClick={() => handleSyncScores(selectedAssessmentIds)}
              text="Sync now"
              type="button"
              disabled={!selectedAssessments.length}
            />
          </div>
        </div>
      </div>
      <div className="grade-sync__activity-container">
        <div className="grade-sync__menubar">
          My Grade Sync Activity
        </div>
        <table>
          <thead>
            <tr>
              <th>
                Assessment Name
              </th>
              <th>
                Date and Time Sync Began
              </th>
              <th>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(syncStatuses).map(([assessmentId, stat]) => (
              <tr key={`stat-row_${assessmentId}`}>
                <td>
                  {stat.name}
                </td>
                <td>
                  {stat?.startTimestamp ? DateTime.fromISO(stat.startTimestamp).toFormat(DateFormatEnum.DateAtTimeMeridean) : ''}
                </td>
                <td>
                  {getStatusMessage(stat)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

GradeSync.propTypes = {
  assessments: PropTypes.array.isRequired,
  handleStudentScoresView: PropTypes.func.isRequired,
  handleAssessmentSync: PropTypes.func.isRequired,
};

export default GradeSync;

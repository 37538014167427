import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from 'store';
import activeSlice from 'store/slices/active';
import CourseHeaderNav from 'shared-components/CourseHeaderNav/CourseHeaderNav';
import CourseList from 'shared-components/CourseList/CourseList';
import CourseDetailsController from '../Course/CourseDetailsController/CourseDetailsController';
import { RoleEnum } from 'types/backend/roles.types';
import './Home.scss';

export default function Home() {
  const instructorCourses = useAppSelector((store) => store.passive.instructorCourses);
  const studentCourses = useAppSelector((store) => store.passive.studentCourses);
  const course = useAppSelector((store) => store.active.course);
  const coinstructorsFromEnrolledCourses = useAppSelector(store => store.passive.coinstructorsFromEnrolledCourses); const dispatch = useAppDispatch();
  const hasStudentCourses = !!studentCourses?.length;
  useDocumentTitle('My Courses');

  useEffect(() => {
    // if course is in state when within this component, it should be cleared to avoid carrying redux state to other courses
    if (!!course.id) {
      console.warn('Course is defined when it should not be', course);
      dispatch(activeSlice.actions.clear());
    }
  }, [course, dispatch]);
  return (
    <main className="instructor-home home">
      <CourseHeaderNav
        leftTabs={[{
          id: 'instructor-nav-item__my-courses',
          label: 'My Courses',
          coursePath: '/instructor',
          exact: true,
        }]}
        rightTabs={[{
          id: 'instructor-nav-item__add-new-course',
          label: 'Add New Course',
          coursePath: '/instructor/create-course',
          exact: true,
        }]}
        title="Welcome to Codon Learning"
      />
      <div className="home__main">
        <Switch>
          <Route exact path="/instructor">
            {!!instructorCourses?.length && (
              <CourseList
                linkPrefix="/instructor"
                courses={instructorCourses}
                enrollmentRole={RoleEnum.Instructor}
                showRoleHeader={hasStudentCourses}
                coinstructorsFromEnrolledCourses={coinstructorsFromEnrolledCourses}
              />
            )}
            {hasStudentCourses && (
              <>
                <hr/>
                <CourseList
                  linkPrefix="/student"
                  courses={studentCourses}
                  enrollmentRole={RoleEnum.Student}
                  showRoleHeader
                />
              </>
            )}
          </Route>
          <Route exact path="/instructor/create-course">
            <CourseDetailsController type="new" />
          </Route>
        </Switch>
      </div>
    </main>
  );
}

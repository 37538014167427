import React from 'react';
import { DateTime } from 'luxon';
import ToasterNotification, { ToasterFlavorEnum } from './ToasterNotification';

function DowntimeNotification({ show = true }: { show?: boolean }) {
  if (!show) {
    return null;
  }
  const downtimeISO = '2024-02-23T05:00:00-05:00';
  const downtimeDuration = { hours: 1 };
  const downtimeStartLuxon = DateTime.fromISO(downtimeISO).toLocal();
  const downtimeDate = downtimeStartLuxon.toFormat('EEEE M/d');
  const timeStart = downtimeStartLuxon.toFormat('h a');
  const timeEnd = downtimeStartLuxon.plus(downtimeDuration).toFormat('h a');
  const timeZone = downtimeStartLuxon.toFormat('ZZZZ');
  return (
    <ToasterNotification
      id="downtime-notification-4.3.0"
      flavor={ToasterFlavorEnum.Downtime}
      message={(
        <div>
          We're working on improvements! Codon Learning will be briefly unavailable {downtimeDate} between {timeStart} and {timeEnd} {timeZone}.
        </div>
      )}
    />
  );
}

export default DowntimeNotification;

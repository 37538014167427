import { EnrollmentApi } from 'types/backend/enrollments.types';
import { CourseApi } from 'types/backend/courses.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { UnitApi } from 'types/backend/units.types';
import { TopicApi } from 'types/backend/topics.types';
import { ProductApi } from 'types/backend/products.types';
import { UserApi } from 'types/backend/users.types';

const defaultState = {
  // User object with properties for the currently logged in user
  user: null,
  adminUser: null, // object for original admin user object while masquerading
  //enrollments are the course user is enrolled in (eventually we may want to combine user and enrollment and other user data into a common group)
  enrollments: [] as Array<EnrollmentApi>, // An array of all the enrollments
  // This is all data reused between or outside of specific courses
  passive: {
    instructorCourses: [] as Array<CourseApi>,                // An array of all the courses that the logged in user is enrolled in as an instructor
    studentCourses: [] as Array<CourseApi>,                   // An array of all the courses that the logged in user is enrolled in as a student
    coinstructorsFromEnrolledCourses: [] as Array<UserApi>,   // An array of all the coinstructors for the courses the logged in user is enrolled in as an instructor
    subjects: [] as Array<SubjectApi>,                        // An array of all subjects (formerly topicOnes) from the library
    units: [] as Array<UnitApi>,                              // An array of all units (formerly topicTwos) from the library
    topics: [] as Array<TopicApi>,                            // An array of all topics (formerly topicThrees)  from the library
    products: [] as Array<ProductApi>,                        // An array of all products
  },

  // This is data that is "in play". meaning it can be modified by the application logic and or it is loaded based on specific state of the app (like a course being active)
  active: {
    assessments: [],                // An array of all the assessments (metadata) in the active course
    courseAssessmentPreset: {},     // CourseAssessmentPreset data in the active course
    templateQuestions: [],          // Fully hydrated (with full references) template questions. TODO this feels like a lot of questions and data, may need to limit to just metadata and just to the active courselibrary (topic_one)
    userQuestions: [],              // A list of all questions for a user.
    course: null,                   // Top level information for the active course once a user has selected one
    classSessions: [],              // Class sessions for the active course, sorted sequentially by their date (and eventually time) by default
    instructors: [],                // All instructors enrolled in the active course
    templateLearningObjectives: [], // TemplateLearningObjectives loaded for the just the currently active course
    units: [],
    topics: [],
    currentClassSession: null,
    assessmentQuestionMaps: [],
    questionGroups: [],
    summativeAssessmentSupplements: [], // used to link summative/prep/practice
    studentStudyPath: {},               // current student study path data
    studentAssessments: [],             // all assessments for the enrollment in the active course of the logged in user
    studentAssessmentQuestions: [],       //all studentAssessmentQuestions for the enrollment in the active course of the logged in user
    enrollmentAssessments: [],
    students: [],                    // all student users in the course
  },

  // Global state variables
  state: {
    instructorStudentViewCourseId: null,  //setting this to a course id shows the student view to an instructor for a specific course. Clearing it returns back to the instructor screen
  },
};

export default defaultState;

import useDocumentTitle from 'hooks/useDocumentTitle';
import React from 'react';
import './Login.scss';

function LoginBox({
  children,
  hideSubHead = false,
  notificationMsg = null,
  title,
}: {
  children?: React.ReactNode | Array<React.ReactNode>
  hideSubHead?: boolean
  notificationMsg?: React.ReactNode
  title: string
}) {
  useDocumentTitle(title);
  return (
    <main className="login">
      <div className="login__box">
        <h1>
          <span>Codon</span>
          <span>Learning</span>
        </h1>
        {notificationMsg && (
          <div className="notification-message">
            {notificationMsg}
          </div>
        )}
        {!hideSubHead && <h2>{title}</h2>}
        {children}
      </div>
    </main>
  );
}

export default LoginBox;

// sourced from https://github.com/codonlearning/backend/blob/dev/src/types/error.types.ts
import { GenericObject } from 'types/backend/shared.types';

export interface ErrorData extends GenericObject {
  method: string
  path: string
}

export enum CodonErrorCode {
  // 400s
  BadRequest = '40000', // TODO not used yet
  MissingRequiredData = '40001',
  L8yItemsReqBadTags = '40002',
  LtiInvalidOidcLogin = '40003',
  LtiLaunchValidationError = '40004',
  MissingRequiredEnvVar = '40005',
  GeneralPaymentConfirmationError = '40006',
  GeneralStripeWebhookError = '40007',
  SyncL8yItemsMissingField = '40008',
  L8yItemMissingTag = '40009',
  NonLtiUserMissingPassword = '40010',
  LOsBySubjectBadWhereOr = '40011',
  L8yItemEmptyTagsOrQuestions = '40012',
  InvalidLtiAuthenticationRequest = '40013',
  LtiPlatformRejected = '40014',
  CCProductMissingStripePriceId = '40015',
  // 401s
  LaunchTokenNotFound = '40101',
  LaunchTokenExpired = '40102',
  // 403s
  Forbidden = '40300',
  UserWriteTemplate = '40301',  // user tries to write to a template object
  UserWriteOtherUser = '40302', // user tries to write to another user's object
  UserDoesNotMatchReqUser = '40303',    // request user does not match the userId in the request data
  UserNotInstructorInCourse = '40304',
  CourseCreatorNotInstructor = '40305',
  SystemAdminEnrollInCourse = '40306',
  UserNotStudentInCourse = '40307',
  UserGetOtherUser = '40308',
  NonAdminChangeSpecialUserType = '40309',
  InvalidUserAssertion = '40310',
  DeletePrepOrPT = '40311',
  // 404s
  NotFound = '40400',
  AccessCodeNotFound = '40401',
  EnrollmentNotFound = '40402',
  PreviousIdNotFound = '40403',
  AssessTypeNotFound = '40404',
  CourseCreatorUserNotFound = '40405',
  EnrollmentOrAssessmentNotFound = '40406',
  LtiIssuerNotFound = '40407',
  LtiCourseWithContextIdNotFound = '40408',
  LtiUserForRoleNotFound = '40409',
  LtiInvalidLtiRole = '40410',
  PullL8yDataUnknownReportType = '40411',
  L8yReferenceNotFound = '40412',
  GradingPolicyNotFound = '40413',
  CheckpointNotFound = '40414',
  L8yItemTypeNotFound = '40415',
  L8yCompetencyNotFound = '40416',
  L8yLearningObjectiveNotFound = '40417',
  L8ySubjectNotFound = '40418',
  L8yBloomsNotFound = '40419',
  L8yQuestionUseNotFound = '40420',
  CreateSAQAAQNotFound = '40421',
  DuplicateCourseUserNotFound = '40422',
  LineItemNotFound = '40423',
  ImportAQsSourceAssessmentNotFound = '40424',
  ImportAQsTargetAssessmentsNotFound = '40425',
  PreviousRecordNotFound = '40426',
  DuplicateCourseCoinstructorUsersNotFound = '40427',
  // 422s
  Unprocessable = '42200',  // TODO not used yet
  InvalidAccessCodeStatus = '42201',
  InvalidEnrollmentStatus = '42202',
  InvalidAssessmentQuestionAction = '42203',
  InvalidAssessmentAction = '42204',
  EditClassSessionId = '42205',
  EditClassSessionLearningObjective = '42208',
  EditClassSessionLearningObjectiveTopic = '42209',
  InvalidCourseAction = '42210',
  CourseNotEnrollable = '42211',
  InvalidEnrollmentAssessmentAction = '42212',
  InvalidEnrollmentRole = '42213',
  InvalidEnrollmentMethod = '42214',
  InvalidEnrollmentPatchAction = '42215',
  LtiLinkAlreadyAssociated = '42216',
  InvalidQuestionLearningObjectiveAction = '42217',
  InvalidQuestionAction = '42218',
  QuestionMissingCorrectAnswer = '42219',
  L8yItemInvalidTags = '42220',
  L8yItemInvalidStatus = '42221',
  InvalidSAQAInAT = '42222',
  CreateSAAssessmentNotPublished = '42223',
  CreateSABeforeOpenDate = '42224',
  CreateSAPTNoSPorTCs = '42225',
  CreateSAPTTooEarlyOrMoveCards = '42226',
  StudentScoresDataUserIdMismatch = '42227',
  CreateSSPSummativeNotPublished = '42228',
  MoveStudentTopicCardInvalidReqData = '42229',
  MoveStudentTopicCardInvalidSkip = '42230',
  MoveStudentTopicCardInvalidCheckpoint = '42231',
  MoveStudentTopicCardUnableToDetermineNewCheckpoint = '42232',
  TemplateContentInUserContent = '42233',
  InvalidClassSessionSelected = '42234',
  InvalidClassSessionAction = '42235',
  InvalidAssessmentQuestionActionWithAttempts = '42236',
  L8yItemSyncUserItem = '42237',
  InvalidGradeSyncAction = '42238',
  EditClassSessionTopic = '42239',
  OpenResponseQuestionMissingSampleAnswer = '42240',
  QuestionAlreadyAssignedInCourse = '42241',
  InvalidShortTitle = '42242',
  CopyUserLearningObjective = '42243',
  ImportAQsSourceHasNoAQs = '42244',
  ImportQsSameSourceTargetUser = '42245',
  CreateSANoAssessmentQuestions = '42246',
  // 500s
  GeneralError = '50000', // TODO not used yet
  LogShipperFailure = '50001',
  LtiGeneralError = '50002'
}

/**
 * retrieveSortedActiveCombinedQuestions
 * Sorts all ActiveCombinedQuestions, should only be used by instructors.
 **/
import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash-es/uniqBy';

import retrieveActiveCourseLearningObjectives from './retrieveActiveCourseLearningObjectives';
import retrieveActiveCombinedQuestions, { ActiveCombinedQuestion } from './retrieveActiveCombinedQuestions';
import retrieveEnrichedActiveTemplateLearningObjectives from './retrieveEnrichedActiveTemplateLearningObjectives';

import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { UnitApi } from 'types/backend/units.types';
import { Store } from 'types/store.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';

export const sortActiveCombinedQuestions = (
  units: Array<UnitApi>,
  topics: Array<TopicApi>,
  learningObjectives: Array<LearningObjectiveApi>,
  qs: Array<ActiveCombinedQuestion>,
  cslos: Array<ClassSessionLearningObjectiveApi>
) => {
  let orderIndex = 0;
  const sortedQuestions = units.map((unit) => {
    // filter so it's only topics used in units
    const unitTopics = topics.filter(topic => topic.unitId === unit.id);
    return unitTopics.map((unitTopic) => {
      const libraryTopicLoIds = learningObjectives.filter(lo => lo.topicId === unitTopic.id).map(({ id }) => id);
      // get additional lo ids from classSessionLearningObjectives because custom
      const csloLoTopicIds = cslos.filter(({ topicId }) => topicId === unitTopic.id).map(({ learningObjectiveId }) => learningObjectiveId);
      // merge LOs uniquely
      const combinedLos = [...new Set([...libraryTopicLoIds, ...csloLoTopicIds])];
      return combinedLos.map((topicLoId) => {
        const loQuestions = qs.filter(({ learningObjectiveIds }) => {
          return !!topicLoId && learningObjectiveIds && learningObjectiveIds.includes(topicLoId);
        });
        const sortedLOQuestions = loQuestions.sort((a, b) => a.blooms - b.blooms);
        return sortedLOQuestions.map((loq) => {
          // libraryOrder is currently not used anywhere else in the codebase
          orderIndex = orderIndex + 1;
          return {
            ...loq,
            libraryOrder: orderIndex,
          };
        });
      }).flat();
    }).flat();
  }).flat();
  return uniqBy(sortedQuestions, 'id') as Array<ActiveCombinedQuestion>;
};

export default createSelector(
  retrieveActiveCombinedQuestions,
  (store: Store) => store.active.units,
  (store: Store) => store.active.topics,
  (store: Store) => store.active.learningObjectives,
  retrieveActiveCourseLearningObjectives,
  retrieveEnrichedActiveTemplateLearningObjectives,
  (store: Store) => store.active.classSessionLearningObjectives,
  (
    activeCombinedQuestions,
    units,
    topics,
    learningObjectives,
    classSessionLearningObjectives
  ) => {
    // Sort questions
    // for now questions are sorted consistently with what is in the codon content library, based on the first LO for each question
    if (!units.length || !topics.length) {
      return activeCombinedQuestions;
    }
    const sortedQuestions = sortActiveCombinedQuestions(units, topics, learningObjectives, activeCombinedQuestions, classSessionLearningObjectives);
    const sortedQuestionIds = sortedQuestions.map(q => q.id);
    const remainingQuestions = activeCombinedQuestions.filter(q => !sortedQuestionIds.includes(q.id));
    return [...sortedQuestions, ...remainingQuestions];
  }
);

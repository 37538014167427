import React from 'react';
import CodonUrls from 'urls';
import ExternalLink from 'shared-components/ExternalLink/ExternalLink';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { YesNo } from 'types/backend/shared.types';

function VatFrozenMessage({
  assessType,
  attemptPolicy,
  currentQuestionIsCorrect,
  currentQuestionEverCorrect,
  isAfterLate,
  currentItemIsAttempted,
}: {
  assessType: AssessTypeEnum | null
  attemptPolicy?: MultipleAttemptPolicyEnum
  currentQuestionIsCorrect?: YesNo
  currentQuestionEverCorrect: YesNo
  isAfterLate?: boolean
  currentItemIsAttempted?: boolean
}) {
  const renderMessage = () => {
    const studyPathMessage = (
      <>
        Want to retry this question? You can do that in the Study Path!
      </>
    );
    if (isAfterLate && assessType !== AssessTypeEnum.Readiness) {
      return studyPathMessage;
    }
    switch (assessType) {
      case AssessTypeEnum.PracticeTest: {
        return (
          <>
            You have attempted this question. After completing the Practice Test, head to the Study Path and click "Review Practice Test Questions" to see how you did.
          </>
        );
      }
      case AssessTypeEnum.Homework:
      case AssessTypeEnum.Preclass: {
        const reviewInStudyPathMessage = (
          <>
            After completing the assignment, head to the Study Path to review this question and others like it.
          </>
        );
        if (!!currentItemIsAttempted) {
          return (
            <>
              You have attempted this question. {reviewInStudyPathMessage}
            </>
          );
        }
        if (currentQuestionIsCorrect === YesNo.Yes || currentQuestionEverCorrect === YesNo.Yes) {
          return (
            <>
              You answered this question correctly. {reviewInStudyPathMessage}
            </>
          );
        } else {
          const incorrectHomeworkForCorrectness = attemptPolicy && assessType === AssessTypeEnum.Homework && [
            MultipleAttemptPolicyEnum.CorrectnessTypeOne,
            MultipleAttemptPolicyEnum.CorrectnessTypeTwo,
            MultipleAttemptPolicyEnum.CorrectnessTypeThree,
          ].includes(attemptPolicy);

          const incorrectForCompletionOrNoPoints = attemptPolicy && [
            MultipleAttemptPolicyEnum.CorrectnessTypeFour,
            MultipleAttemptPolicyEnum.ForCompletion,
            MultipleAttemptPolicyEnum.NotForPoints,
          ].includes(attemptPolicy);

          if (incorrectHomeworkForCorrectness) {
            return (
              <>
                You have missed this question twice. After completing the assignment, head to the Study Path to recapture points on missed questions.
                &nbsp;
                <ExternalLink url={CodonUrls.HowToRecaptureKB} text="Learn More." aria-label="Read about point recapture in our knowledge base" />
              </>
            );
          } else if (incorrectForCompletionOrNoPoints) {
            return (
              <>
                {assessType === AssessTypeEnum.Homework ? 'You have missed this question twice.' : ''} {reviewInStudyPathMessage}
              </>
            );
          }
        }
        return studyPathMessage;
      }
      case AssessTypeEnum.Readiness: {
        return (
          <>
            The assignment is no longer for points. Work is for practice only.
          </>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <div className="vat-study-path-hint-banner" aria-live={AriaLiveAttribute.Polite}>
      {renderMessage()}
    </div>
  );
}

export default VatFrozenMessage;

import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { FormattedL8yResponse } from 'types/backend/studentResponses.types';
import { ScoreDetail, StudentAssessmentStatus } from 'types/backend/studentScoresData.types';

export interface EnrichedAssessmentForStudentScores extends AssessmentApiBase {
  totalPoints: number
  isOpen: boolean
  questions: Array<EnrichedAssessmentQuestionForStudentScores>
}

export interface EnrichedAssessmentQuestionForStudentScores extends AssessmentQuestionApi {
  qNum: number
  title: string
  l8yId: string
  sourceQuestion: QuestionApiOut
  correctResponse?: FormattedL8yResponse
  muddyAttemptData: MuddyAttemptData | null
}

export interface StudentScoresStudent {
  id: string
  studentName: string
  enrollmentId: number
}

export interface StudentScoresRowScores extends ScoreDetail {
  assessmentId: string
}

export interface StudentScoresRow {
  studentName: string
  studentEnrollmentId: number
  scores: Array<StudentScoresRowScores>
}

export interface AssessmentQuestionAnswer {
  questionId: number
  hasBeenAttempted: boolean
  score: number
  firstAttempted: FirstAttemptedEnum
}

export interface AssessmentGridRow {
  userId: string
  studentName: string
  assessmentStatus: StudentAssessmentStatus
  assessmentQuestionAnswers: Array<AssessmentQuestionAnswer>
}

export enum StudentScoresViewEnum {
  ALL_ASSESSMENTS_VIEW = 'ALL',
  ASSESSMENT_DETAIL_VIEW = 'DETAIL',
  ASSESSMENT_QUESTION_VIEW = 'ASSESSMENT_QUESTION',
  GRADE_SYNC_VIEW = 'GRADE_SYNC_VIEW'
}

export interface MuddyAttemptData {
  numerator: number
  denominator: number
  percentage: number
}

export enum ScoresType {
  Scores = 'Scores',
  Responses = 'Responses'
}

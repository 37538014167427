import React from 'react';
import PropTypes from 'prop-types';
import { FaEye, FaChevronLeft } from 'react-icons/fa';

import { truncate } from 'utils';
import { formatPoints, getStatusMessageForAssessmentStatus } from 'utils/commonFormattingFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import sharedStrings from 'sharedStrings';
import CodonUrls from 'urls';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import MuddyBar from './MuddyBar';
import StudentScoresTable from './StudentScoresTable';
import GradeSync from './GradeSync/GradeSync';

import {
  EnrichedAssessmentForStudentScores,
  EnrichedAssessmentQuestionForStudentScores,
  StudentScoresRow,
  StudentScoresViewEnum,
  AssessmentGridRow,
} from '../../studentScores.types';
import { BasicQuestionForPreview, formatBasicQuestionForPreview } from 'utils/getAssessmentQuestionsMetadata';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentSummaryData } from 'types/backend/assessmentSummaryData.types';
import { AssessmentScoreSyncApi } from 'types/backend/assessmentScoreSync.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { StudentResponseRes } from 'types/backend/studentResponses.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import { PositionEnum } from 'types/common.types';
import './StudentScoresContainer.scss';

const questionTitleCharLimit = 64;

type StudentScoresContainerTypes = {
  activeAssessment: EnrichedAssessmentForStudentScores
  activeAssessmentQuestion: EnrichedAssessmentQuestionForStudentScores
  assessmentGrid: Array<AssessmentGridRow>
  assessments: Array<EnrichedAssessmentForStudentScores>
  handleStudentScoresView: (scoresView: StudentScoresViewEnum, selectedId?: number | string) => void
  loadAndSaveStudentScoresData: () => void
  loadAndSaveStudentResponsesData: () => void
  questions: Array<EnrichedAssessmentQuestionForStudentScores>
  setShowQuestionPreview: (questionData: BasicQuestionForPreview) => void
  studentAssessmentQuestionResponses: Array<StudentResponseRes>
  studentScores: Array<StudentScoresRow>
  studentScoresView: StudentScoresViewEnum
  assessmentsSummaryData: Array<AssessmentSummaryData>
  handleAssessmentSync: (assessmentIds: Array<string>) => Promise<Array<AssessmentScoreSyncApi>>
  loadAssessmentSummaryData: (assessmentIdsToLoad: Array<string>) => Promise<void>
}

function StudentScoresContainer(props: StudentScoresContainerTypes) {
  const {
    assessments,
    activeAssessment,
    activeAssessmentQuestion,
    studentScores,
    studentScoresView,
    assessmentGrid,
    handleStudentScoresView,
    questions,
    setShowQuestionPreview,
    studentAssessmentQuestionResponses,
    loadAndSaveStudentScoresData,
    loadAndSaveStudentResponsesData,
    assessmentsSummaryData,
    handleAssessmentSync,
    loadAssessmentSummaryData,
  } = props;

  const { correctResponse = [] } = activeAssessmentQuestion;

  const handleQuestionPreview = (assessmentQuestion: EnrichedAssessmentQuestionForStudentScores) => {
    const { sourceQuestion } = assessmentQuestion;
    const basicQuestionForPreview = formatBasicQuestionForPreview(sourceQuestion);
    setShowQuestionPreview(basicQuestionForPreview);
  };

  function renderAssessmentDetailHeader(assessmentQuestions: Array<EnrichedAssessmentQuestionForStudentScores>, assessType: AssessTypeEnum) {
    const clickableHeaders = assessType !== AssessTypeEnum.Summative;
    return (
      <thead>
        <tr>
          <th>
            Student Name
          </th>
          <th>
            Assessment Status
          </th>
          {assessmentQuestions.map(aq => (
            <th key={aq.qNum} className="score-book-container__header-question">
              {clickableHeaders ? (
                <div className="score-book-container__header">
                  <div className="score-book-container__data-row">
                    <button
                      onMouseEnter={() => console.debug('question', aq)}
                      className="score-book-container__header__preview-button"
                      onClick={() => handleStudentScoresView(StudentScoresViewEnum.ASSESSMENT_QUESTION_VIEW, aq.id)}
                      title={aq.title}
                    >
                      Q{aq.qNum}: {truncate(aq.title, questionTitleCharLimit)}
                    </button>
                  </div>
                  <div className="score-book-container__data-row">
                    <div>({formatPoints(aq.points)} pts)</div>
                    <button className="score-book-container__mini-icon" onClick={() => handleQuestionPreview(aq)}>
                      <FaEye />
                    </button>
                  </div>
                  <div className="score-book-container__data-row">
                    <MuddyBar muddyAttemptData={aq.muddyAttemptData}/>
                  </div>
                </div>
              ) : (
                <div
                  className="score-book-container__header"
                  onMouseEnter={() => console.debug('question', aq)}
                  title={aq.title}
                >
                  Q{aq.qNum}: {truncate(aq.title, questionTitleCharLimit)}
                  <div className="score-book-container__data-row">
                    <div>({formatPoints(aq.points)} pts)</div>
                    <button className="score-book-container__mini-icon" onClick={() => handleQuestionPreview(aq)}>
                      <FaEye />
                    </button>
                  </div>
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  function renderAssessmentDetailRows(assessmentGridData: Array<AssessmentGridRow>) {
    return (
      <tbody>
        {assessmentGridData.map(({ userId, studentName, assessmentStatus, assessmentQuestionAnswers }) => {
          const statusMessage = getStatusMessageForAssessmentStatus(assessmentStatus);
          return (
            <tr key={`assessment-grid-row_${userId}`}>
              <td>{studentName}</td>
              <td>{statusMessage}</td>
              {assessmentQuestionAnswers.map((aqa) => {
                const showLateSuffix = [FirstAttemptedEnum.BeforeLate, FirstAttemptedEnum.AfterLate].includes(aqa.firstAttempted);
                const isAfterDue = [
                  StudentAssessmentStatus.NotStartedBeforeLate,
                  StudentAssessmentStatus.NotStartedAfterLate,
                  StudentAssessmentStatus.InProgressBeforeLate,
                  StudentAssessmentStatus.InProgressAfterLate,
                  StudentAssessmentStatus.CompletedBeforeLate,
                  StudentAssessmentStatus.CompletedAfterLate,
                ].includes(assessmentStatus);
                let score = formatPoints(aqa.score);
                let tdClass = '';
                if (!aqa.hasBeenAttempted) {
                  if (!isAfterDue) {
                    score = '-';
                  } else {
                    tdClass = 'unattempted-and-late';
                  }
                }
                return (
                  <td key={`assessment-grid-cell_${userId}-${aqa.questionId}`} className={tdClass}>{score}{showLateSuffix && sharedStrings.LATE_SUFFIX}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  function renderAssessmentQuestionResponseGridHeader() {
    return (
      <thead>
        <tr>
          <th>Student Name</th>
          <th>Points Earned</th>
          <th>
            # of Attempts until Correct
            <BetterTooltip
              indicate
              content='includes attempts that may not be for points (for example, in the Study Path after the due date)'
              position={PositionEnum.BottomRight}
            />
          </th>
          <th className="score-book-container__header-response">Attempt 1:<br/>Response</th>
          <th className="score-book-container__header-correct-clarity">Attempt 1:<br/>Correct/Incorrect</th>
          <th className="score-book-container__header-correct-clarity">Attempt 1:<br/>Muddy/Clear</th>
          <th className="score-book-container__header-response">Attempt 2:<br/>Response</th>
          <th className="score-book-container__header-correct-clarity">Attempt 2:<br/>Correct/Incorrect</th>
          <th className="score-book-container__header-correct-clarity">Attempt 2:<br/>Muddy/Clear</th>
        </tr>
      </thead>
    );
  }

  function renderAssessmentQuestionResponseGridRows(studentAssessmentQuestionResponsesData: Array<StudentResponseRes>) {
    return (
      <tbody>
        {studentAssessmentQuestionResponsesData.map(saq => (
          <tr key={`saqResponseUserId_${saq.userId}`}>
            <td>{saq.studentName}</td>
            <td>{saq.pointsEarned}</td>
            <td>{saq.firstCorrectAttemptNum}</td>
            <td>{saq.attempt1Response}</td>
            <td>{saq.attempt1Correct}</td>
            <td>{saq.attempt1Clarity}</td>
            <td>{saq.attempt2Response}</td>
            <td>{saq.attempt2Correct}</td>
            <td>{saq.attempt2Clarity}</td>
          </tr>
        ))}
      </tbody>
    );
  }

  function renderStudentScores() {
    switch (studentScoresView) {
      case StudentScoresViewEnum.ASSESSMENT_DETAIL_VIEW:
        return (
          <>
            <div className="score-book-container__title-bar">
              <div className="score-book-container__button-bar">
                <BetterButton
                  className="score-book-container__title-bar-link"
                  onClick={() => handleStudentScoresView(StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW)}
                  text="Back"
                  icon={() => <FaChevronLeft />}
                  secondary
                />
                <div>Student scores for <b>{activeAssessment.name}</b></div>
              </div>
              <div className="score-book-container__header-bar__info">
                <BetterTooltip
                  content={
                    <div>
                      Click on a column header to see details of students' attempts for that question.<br/><br/>
                      <b><u>Assessment Status</u></b><br/>
                      <b>Not Started</b> = No questions attempted yet and the late date hasn't passed<br/>
                      <b>In Progress</b> = Some questions attempted and the late date hasn't passed<br/>
                      <b>Completed on Time</b> = All questions attempted before due date<br/>
                      <b>Completed Late</b> = All questions attempted before the late date, but 1+ were first attempted after the due date<br/>
                      <b>Incomplete</b> = Not all questions attempted and the late date has passed<br/>
                      <b>Completed After Late Date</b> = All questions attempted, but one or more were first attempted after the late date<br/><br/>
                      <b><u>Question Scores</u></b><br/>
                      "<b>Late</b>" = Question only attempted for the first time after the due date<br/>
                      <b>Red shading</b> = Question never attempted<br/><br/>
                      {externalLink(CodonUrls.LatePolicyKB, 'Learn more.')}
                    </div>
                  }
                  indicate
                  position={PositionEnum.Bottom}
                >
                  {sharedStrings.HOW_TO_INTERPRET}
                </BetterTooltip>
              </div>
            </div>
            <div className="score-book-container__assessment-grid-table-wrapper">
              <table className="student-scores-container__table">
                {renderAssessmentDetailHeader(questions, activeAssessment.assessType)}
                {renderAssessmentDetailRows(assessmentGrid)}
              </table>
            </div>
          </>
        );
      case StudentScoresViewEnum.ASSESSMENT_QUESTION_VIEW:
        return (
          <>
            <div className="score-book-container__title-bar">
              <BetterButton
                className="score-book-container__title-bar-link"
                onClick={() => handleStudentScoresView(StudentScoresViewEnum.ASSESSMENT_DETAIL_VIEW, activeAssessment.id)}
                text="Back"
                icon={() => <FaChevronLeft />}
                secondary
              />
              <div>
                Student Responses to <b>{activeAssessment.name} Question {activeAssessmentQuestion.qNum} ({formatPoints(activeAssessmentQuestion.points)} pts)</b>
                <button
                  className="score-book-container__mini-icon"
                  onClick={() => handleQuestionPreview(activeAssessmentQuestion)}
                >
                  <FaEye />
                </button>
              </div>
              <BetterButton
                onClick={loadAndSaveStudentResponsesData}
                text="Download Responses .csv File"
                className="student-scores__download-button"
                primary
              />
            </div>
            <div className="score-book-container__correct-response-bar">
              {activeAssessmentQuestion.sourceQuestion.gradingType === GradingTypeTag.Survey
                ? <>This is a survey item. {externalLink(CodonUrls.SurveyItemsKB, 'Learn more.')}</>
                : <>Correct response: {correctResponse}</>
              }
            </div>
            <div className="score-book-container__assessment-grid-table-wrapper">
              <table className="student-scores-container__table">
                {renderAssessmentQuestionResponseGridHeader()}
                {renderAssessmentQuestionResponseGridRows(studentAssessmentQuestionResponses)}
              </table>
            </div>
          </>
        );
      case StudentScoresViewEnum.GRADE_SYNC_VIEW:
        return (
          <GradeSync
            assessments={assessments}
            handleStudentScoresView={handleStudentScoresView}
            handleAssessmentSync={handleAssessmentSync}
          />
        );
      case StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW:
      default:
        return (
          <StudentScoresTable
            assessments={assessments}
            handleStudentScoresView={handleStudentScoresView}
            loadAndSaveStudentScoresData={loadAndSaveStudentScoresData}
            studentScores={studentScores}
            assessmentsSummaryData={assessmentsSummaryData}
            loadAssessmentSummaryData={loadAssessmentSummaryData}
          />
        );
    }
  }

  return (
    <div className="score-book-container">
      <main className="score-book-container__body">
        {renderStudentScores()}
      </main>
    </div>
  );
}

StudentScoresContainer.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
  activeAssessment: PropTypes.object,
  activeAssessmentQuestion: PropTypes.object,
  studentScores: PropTypes.arrayOf(PropTypes.object),
  studentScoresView: PropTypes.oneOf(Object.values(StudentScoresViewEnum) as Array<StudentScoresViewEnum>).isRequired,
  assessmentGrid: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.arrayOf(PropTypes.object),
  handleStudentScoresView: PropTypes.func.isRequired,
  setShowQuestionPreview: PropTypes.func,
  studentAssessmentQuestionResponses: PropTypes.arrayOf(PropTypes.object),
  assessmentsSummaryData: PropTypes.arrayOf(PropTypes.object),
  handleAssessmentSync: PropTypes.func.isRequired,
  loadAssessmentSummaryData: PropTypes.func.isRequired,
};

export default StudentScoresContainer;

// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/studentResponses/studentResponses.types.ts
import { ClarityEnum } from 'types/backend/shared.types';


export enum CorrectIncorrect {
  Correct = 'correct',
  Incorrect = 'incorrect'
}

export type FormattedL8yResponse = string | Array<string | Array<string>>

export interface StudentResponseRes {
  userId: string
  studentName: string
  idPlaceholder: string
  pointsEarned: number | '-'
  firstCorrectAttemptNum: number | 'never correct' | '-'
  attempt1Response: FormattedL8yResponse
  attempt1Clarity: ClarityEnum | '-'
  attempt1Correct: CorrectIncorrect | '-'
  attempt2Response: FormattedL8yResponse
  attempt2Clarity: ClarityEnum | '-'
  attempt2Correct: CorrectIncorrect | '-'
}

export interface StudentResponsesForAssessmentQuestionRes {
  assessmentQuestionId: number
  l8yId: string
  correctResponse: FormattedL8yResponse
  studentResponses: Array<StudentResponseRes>
}

import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import apiNext from 'api-next';
import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import { useAppDispatch } from 'store';
import passiveSlice from 'store/slices/passive';
import { saveLogMessage } from 'utils/saveLogMessage';
import logout from 'utils/logout';

import Home from 'instructor/controllers/Home/Home';
import Course from 'instructor/controllers/Course/Course';
import StudentCourse from 'student/controllers/Course/Course';
import DowntimeNotification from 'shared-components/ToasterNotification/DowntimeNotification';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import GlobalNav from 'shared-components/GlobalNav/GlobalNav';
import { Store } from 'types/store.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
const version = process.env.REACT_APP_VERSION || '0.0.0';

export default function InstructorBase() {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const user = useSelector((store: Store) => store.user);
  const enrollments = useSelector((store: Store) => store.enrollments);
  useIdleLogout(user, IdleLogoutInstance.Instructor);

  const loadPassiveData = useCallback(async (userId: string, userEnrollments: Array<EnrollmentApi>) => {
    try {
      console.debug(`Loading Instructor Passive Data for user: ${userId}`, userEnrollments);
      const instructorCourseIds: Array<string> = [];
      const studentCourseIds: Array<string> = [];
      for (const enrollment of userEnrollments) {
        if (enrollment.roleId === RoleEnum.Instructor) {
          instructorCourseIds.push(enrollment.courseId);
        } else if (enrollment.roleId === RoleEnum.Student) {
          studentCourseIds.push(enrollment.courseId);
        }
      }
      const [subjects, units, topics, instructorCourses, studentCourses, products] = await Promise.all([
        apiNext.getSubjects(),
        apiNext.getUnits(),
        apiNext.getTopics(),
        apiNext.getCourses(instructorCourseIds),
        apiNext.getCourses(studentCourseIds),
        apiNext.getProducts(),
      ]);
      console.debug(`Got Instructor Passive Data | subjects: ${subjects.length}, units: ${units.length}, topics: ${topics.length}, instructor courses: ${instructorCourses?.length}, student courses: ${studentCourses?.length}, products: ${products.length}`);
      let coinstructorsFromEnrolledCourses: Array<UserApi> = [];
      if (!!instructorCourses.length) {
        // note that the logged in instructor is not included in the coinstructors
        // arguably this is a bit of anti-pattern, but it felt wrong to stuff a bunch of unneeded data into Redux
        const courseInstructors = await apiNext.getUsersByCoursesAndRole(instructorCourses.map((c) => c.id), RoleEnum.Instructor);
        coinstructorsFromEnrolledCourses = courseInstructors.filter((ci) => ci.id !== userId);
      }
      dispatch(
        passiveSlice.actions.setPassiveData({
          subjects,
          units,
          topics,
          instructorCourses,
          studentCourses,
          coinstructorsFromEnrolledCourses,
          products,
        })
      );
    } catch (error) {
      console.error('loadPassiveData error', error);
      if (axios.isAxiosError(error) &&
          (error.response?.status === 401 || error.message.includes('401'))) {
        await logout(dispatch);
      } else {
        await saveLogMessage(`WEB ${version} - ${JSON.stringify(error, null, 2)}`);
      }
    }
  }, [dispatch]);

  /**
   * Global Data Fetching Effect
   * When login or reauth is sucesssful and we have a user object,
   * Grab all the non state dependent data from the API and store
   * it for use throughout the application.
  **/
  useEffect(() => {
    if (!user) {
      return;
    }
    loadPassiveData(user.id, enrollments);
  }, [enrollments, loadPassiveData, user]);

  return (
    <div className="instructor-base">
      <div className="app_top">
        <GlobalNav isInstructor userData={user} />
        <DowntimeNotification show={false} />
      </div>
      <div className="app__base">
        <ErrorBoundary isInstructor>
          <Switch>
            <Route exact path={path}><Home /></Route>
            <Route exact path={`${path}/create-course`}><Home /></Route>
            <Route exact path={`${path}/course`}><Course /></Route>
            <Route path={`${path}/course/:id`}><Course /></Route>
            <Route path={`${path}/course/:id`}><StudentCourse /></Route>
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import apiNext from 'api-next';
import sharedStrings from 'sharedStrings';
import {
  determineAssessmentWindow,
  determineMultipleAttemptPolicy,
  handleFinishInstructor,
  totalFromPointsHash,
} from 'utils/assessmentFunctions';
import calculatePointsForRecap from '../shared/calculatePointsForRecap';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import createStudentAssessment from '../shared/createStudentAssessment';
import getVatHashesFromSaqa, { VatHashes } from 'utils/getVatHashesFromSaqa';
import logExecutionTime from 'utils/logExecutionTime';

import { useAppSelector } from 'store';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';

import AssessmentTakerContainer from './AssessmentTakerContainer';
import AssessmentTakerHeader from '../components/AssessmentTakerHeader';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import Instructions from '../components/Instructions';
import IntroOutro from '../components/IntroOutro';
import OutroContent from '../components/OutroContent';
import OutroPracticeTest from '../components/OutroPracticeTest';

import { assessmentDataProp } from '../shared/AssessmentTakerPropTypes';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { L8yExitPayload } from 'shared-components/LearnosityContainer/LearnosityContainer';
import { MappedAssessmentQuestion } from 'types/common.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { AssessmentTakerProps, PageStates, Steps } from '../AssessmentTakerController.types';
import './AssessmentTaker.scss';

function AssessmentTakerRoot({
  assessmentData,
  refreshAssessmentQuestions,
  questions,
  userRoleId,
  userId,
}: {
  assessmentData: AssessmentWithEnrollment
  questions: Array<AssessmentControllerQuestion>
  userId: string
  userRoleId: RoleEnum
  refreshAssessmentQuestions: (assessmentId: string) => Promise<Array<AssessmentControllerQuestion>>
}) {
  const {
    assessType,
    gradingPolicy,
    enrollmentAssessmentDueDate,
    dueDate,
    freeAttempts,
    id: assessmentId,
    lateDate,
    mergedDueDate,
    pointPenalty,
  } = assessmentData;

  const attemptPolicy = determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const isInstructor = useAppSelector((store) => !!store.state.instructorStudentViewCourseId);
  const assessmentQuestionMaps: Array<MappedAssessmentQuestion> = useAppSelector(retrieveActiveAssessmentQuestionMaps);
  const enrichedStudentAssessments = useAppSelector(retrieveEnrichedStudentAssessments);
  const questionL8yIdArray = questions.map(({ l8yId }) => l8yId);
  const [currentStep, setCurrentStep] = useState(Steps.Intro);
  const [correctQuestionCount, updateCorrectQuestionCount] = useState(0);
  const [earnedPoints, updateEarnedPoints] = useState(0);
  const [l8ySessionId, setL8ySessionId] = useState('');

  const [{
    attemptsHash,
    clarityHash,
    correctHash,
    everCorrectHash,
    latePointsDeductedHash,
    pointsHash,
    recapHash,
    vatFrozenHash,
  }, setVatHashes] = useState<VatHashes>({
    attemptsHash: {},
    clarityHash: {},
    correctHash: {},
    everCorrectHash: {},
    latePointsDeductedHash: {},
    pointsHash: {},
    recapHash: {},
    vatFrozenHash: {},
  });

  const [studentAssessmentId, setStudentAssessmentId] = useState(-1);
  const [unansweredQuestionCount, setUnansweredQuestionCount] = useState(-1);
  const [totalLatePointsDeducted, setTotalLatePointsDeducted] = useState(-1);
  const [recapPoints, setRecapPoints] = useState(0);

  useEffect(() => {
    if (assessType === AssessTypeEnum.Homework && currentStep === Steps.Outro) {
      (async () => {
        const points = await calculatePointsForRecap(mergedDueDate, studentAssessmentId);
        setRecapPoints(points);
      })();
    }
  }, [assessType, currentStep, studentAssessmentId, mergedDueDate]);

  const assessmentQuestionMapsForAssessment = assessmentQuestionMaps.filter((aqm: AssessmentQuestionApi) => aqm.assessmentId === assessmentId);
  const totalQuestions = questions.length;
  const filteredAssessmentQuestionMap = assessmentQuestionMapsForAssessment.filter(({ _derived: { questionData: { l8yId } } }) => questionL8yIdArray.includes(l8yId));
  const totalPoints = filteredAssessmentQuestionMap.map(({ points }) => points).reduce((a, b) => a + b, 0);
  const enrollmentData = useAppSelector((store) => store.active.enrollment);
  console.debug('AssessmentTakerRoot', userId, userRoleId, questions, enrollmentData, assessmentData);

  const dueString = DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
  const currentWindow = determineAssessmentWindow(dueDate, lateDate, enrollmentAssessmentDueDate);
  const isAfterLate = currentWindow === FirstAttemptedEnum.AfterLate;
  const handleStep = (step: Steps, force?: boolean) => {
    setCurrentStep(force ? step : PageStates[step].next);
  };

  const handleStartSession = async () => {
    const startTime = performance.now();
    // get the very latest assessmentQuestions in case there has been a last minute change
    const refreshedQuestions = await refreshAssessmentQuestions(assessmentData.id);
    if (!refreshedQuestions.length) {
      triggerConfirmationPrompt({
        title: 'Error Starting Assignment',
        message: 'There are no questions in this assignment. Please check back later.',
        onConfirm: () => {},
      });
      return;
    }
    let studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas> = [];
    // Get role from enrollment
    if (enrollmentData.roleId === RoleEnum.Student) {
      const studentAssessmentData = await createStudentAssessment({ assessmentData, enrollmentData }) as Required<StudentAssessmentApi>;
      setStudentAssessmentId(studentAssessmentData.id);
      setL8ySessionId(studentAssessmentData.l8ySessionId);
      setUnansweredQuestionCount(studentAssessmentData.unansweredQuestionCount);

      studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestionsByStudentAssessmentId(studentAssessmentData.id) as Array<StudentAssessmentQuestionApiWithSaqas>;
    } else if (isInstructor) {
      setL8ySessionId(assessmentId);
    }
    // get initial state of attempt-related data based on latestStudentAssessmentQuestionAttempt for student or default hashes for instructor
    const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, studentAssessmentQuestions, isInstructor, isAfterLate);
    setVatHashes(saqaHashes);
    logExecutionTime(startTime, 'AssessmentTakeRoot handleStartSession');
    handleStep(Steps.Intro);
  };

  const handleReviewAssessment = async () => {
    if (isInstructor) {
      window.location.reload();
    }
    handleStep(Steps.Intro);
  };

  const onFinished = async ({ correctQuestionsCount, ...exitHashes }: L8yExitPayload) => {
    let newTotalPointsEarned = -1;
    let newUnansweredQuestionCount = -1;
    let newCorrectQuestionCount = -1;
    if (isInstructor) {
      newUnansweredQuestionCount = 0;
      ({ totalPointsEarned: newTotalPointsEarned, correctQuestionCount: newCorrectQuestionCount } = handleFinishInstructor(exitHashes));
    } else {
      const updatedStudentAssessments = await apiNext.getStudentAssessment(assessmentData.id, enrollmentData.id);
      const saData = updatedStudentAssessments.find((sa) => sa.assessmentId === assessmentData.id) as StudentAssessmentApi;
      ({ totalPointsEarned: newTotalPointsEarned, unansweredQuestionCount: newUnansweredQuestionCount } = saData);
      newCorrectQuestionCount = correctQuestionsCount;
    }
    if (newTotalPointsEarned !== -1) {
      updateEarnedPoints(newTotalPointsEarned);
    }
    const newTotalLatePointsDeducted = totalFromPointsHash(exitHashes.latePointsDeductedHash);
    if (newTotalLatePointsDeducted !== -1) {
      setTotalLatePointsDeducted(newTotalLatePointsDeducted);
    }
    setVatHashes(exitHashes);
    updateCorrectQuestionCount(newCorrectQuestionCount);
    setUnansweredQuestionCount(newUnansweredQuestionCount);
    handleStep(Steps.Outro, true);
  };

  const renderAssessmentStep = (step: Steps) => {
    const { courseId } = enrollmentData;
    const assessmentTakerProps: AssessmentTakerProps = {
      assessmentData,
      attemptPolicy,
      attemptsHash,
      clarityHash,
      correctHash,
      correctQuestionCount,
      courseId,
      currentStep,
      dueString,
      earnedPoints,
      enrollmentData,
      everCorrectHash,
      handleReviewAssessment,
      handleStartSession,
      l8ySessionId,
      latePointsDeductedHash,
      pointsHash,
      questions,
      recapHash,
      studentAssessmentId,
      totalLatePointsDeducted,
      totalPoints,
      totalQuestions,
      unansweredQuestionCount,
      userId,
      vatFrozenHash,
    };
    const isPracticeTest = assessType === AssessTypeEnum.PracticeTest;
    switch (step) {
      case Steps.Intro: {
        const { mergedOpenDate } = assessmentData;
        const openDateLuxon = DateTime.fromISO(mergedOpenDate);
        let introAction: React.ReactNode = <BetterButton className="gray-button" text="Start" onClick={handleStartSession} />;
        const isOpen = openDateLuxon < DateTime.local() || isInstructor;
        if (!isPracticeTest && !isOpen) {
          introAction = `This assessment will open on ${openDateLuxon.toFormat(DateFormatEnum.WeekdayDateAtTime)}.`;
        }
        return (
          <IntroOutro>
            <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
            <div className="assessment__action">
              {introAction}
            </div>
          </IntroOutro>
        );
      }
      case Steps.Assessment: {
        if (studentAssessmentId === -1 && !isInstructor) {
          console.error('Cannot render Assessment without valid studentAssessmentId', studentAssessmentId);
          return null;
        }
        const inReviewMode = isPracticeTest && unansweredQuestionCount === 0;
        return (
          <AssessmentTakerContainer
            {...assessmentTakerProps}
            assessmentType={assessType}
            handleFinished={onFinished}
            inReviewMode={inReviewMode}
            l8ySessionId={l8ySessionId}
            questions={questions}
            studentAssessmentId={studentAssessmentId}
          />
        );
      }
      case Steps.Outro: {
        if (isPracticeTest) {
          return <OutroPracticeTest {...assessmentTakerProps} />;
        }
        return (
          <OutroContent
            assessmentControllerQuestions={questions}
            assessType={assessType}
            clarityHash={clarityHash}
            correctQuestionCount={correctQuestionCount}
            courseId={enrollmentData.courseId}
            dueString={dueString}
            earnedPoints={earnedPoints}
            gradingPolicy={assessmentData.gradingPolicy}
            handleReviewAssessment={handleReviewAssessment}
            isInstructor={isInstructor}
            recapPoints={recapPoints}
            totalLatePointsDeducted={totalLatePointsDeducted}
            totalPoints={totalPoints}
            totalQuestions={totalQuestions}
          />
        );
      }
    }
  };

  let assessmentStatus = undefined;
  if (enrichedStudentAssessments) {
    const currentAssessment = enrichedStudentAssessments.find(assign => assign.id === assessmentData.id);
    if (currentAssessment) {
      assessmentStatus = currentAssessment.assessmentStatus;
    }
  }

  if (assessmentStatus === undefined) {
    if (currentWindow === FirstAttemptedEnum.BeforeDue) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
    } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
    } else { // after late
      assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
    }
  }

  return (
    <div className="assessment-taker-root page-centered">
      <AssessmentTakerHeader
        assessmentData={assessmentData}
        assessmentStatus={assessmentStatus}
        totalPoints={totalPoints}
      />
      {isInstructor && (
        <div className="assessment-taker-root__instructor-banner">
          {sharedStrings.INSTRUCTOR_PREVIEW_ASSESSMENT}
          <button onClick={() => window.location.reload()}>
            {sharedStrings.INSTRUCTOR_PREVIEW_RESTART_ASSESSMENT}
          </button>
        </div>
      )}
      <div className="assessment-taker-root__wrap">
        { renderAssessmentStep(currentStep) }
      </div>
    </div>
  );
}

AssessmentTakerRoot.propTypes = {
  assessmentData: assessmentDataProp,
  questions: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  userRoleId: PropTypes.number.isRequired,
  refreshAssessmentQuestions: PropTypes.func.isRequired,
};

export default AssessmentTakerRoot;

import React from 'react';
import PropTypes from 'prop-types';

import { productStrings } from 'sharedStrings';
import { ProductApi } from 'types/backend/products.types';
import { YesNo } from 'types/backend/shared.types';

const ProductDetails = ({ product }: {
  product: ProductApi
}) => {
  return (
    <>
      Details of selected product:
      <ul>
        <li>{product.showCode === YesNo.Yes ? productStrings.ALLOWS : productStrings.DOES_NOT_ALLOW} access codes</li>
        <li>{product.showCC === YesNo.Yes ? productStrings.ALLOWS : productStrings.DOES_NOT_ALLOW} credit cards</li>
        <li>See Course Access tab for access instructions</li>
      </ul>
    </>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductDetails;

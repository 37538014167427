import { userFacingQuestionTypes } from 'codonConstants';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { MappedAssessmentQuestion } from 'types/common.types';
import { LibraryTypeEnum, QuestionUseEnum, YesNo } from 'types/backend/shared.types';
import { GradingTypeTag, L8yQuestionType } from 'types/backend/l8y.types';
import { QuestionTypeAbbreviationEnum } from './AssessmentBuilderController.types';

export interface FilterState {
  topics: Array<number>
  los: Array<number>
  blooms: Array<string | number>
  author: Array<LibraryTypeEnum>
  assignment: boolean
  questionUse: Array<QuestionUseEnum>
  questionTypes: Array<QuestionTypeAbbreviationEnum>
  gradingType: Array<GradingTypeTag>
}

const filterQuestions = (
  assessmentQuestionMaps: Array<MappedAssessmentQuestion>,
  questions: Array<ActiveCombinedQuestion>,
  filters: FilterState,
  editingAssessmentId?: string
) => {
  const {
    assignment,
    author,
    blooms,
    los,
    questionTypes,
    questionUse,
    topics,
    gradingType,
  } = filters;

  /** filtering stage one
   *
   * in this stage, adding filter criteria results in more items being returned
   */
  let additiveFiltering = questions.filter((question) => {
    const filterByLos = !!question.courseLearningObjectives.find((clo) => los.includes(clo.id));
    const filterByTopicIds = topics.some((topicId) => {
      const questionHasLibraryTopicId = question._derived.libraryTopicIds.includes(topicId);
      const questionHasCustomTopicId = question.courseLearningObjectives.some((clo) => topics.includes(clo.topicId));
      return questionHasLibraryTopicId || questionHasCustomTopicId;
    });
    return filterByLos || filterByTopicIds;
  });

  // if additive filtering filters out everything, ignore it and just filter the full questions list
  if (!additiveFiltering.length) {
    additiveFiltering = questions;
  }

  /** filtering stage two
   *
   * takes the above list and subtractively filters if conditions are selected for filtering
   * adding filter criteria here results in fewer items being returned
   */
  const subtractiveFiltering = additiveFiltering.filter((question) => {
    // the following filters are subtractive
    // Filter by authorship
    const filterByAuthor = !author.length ? true : author.includes(question.type);
    // Filter by blooms
    const filterByBlooms = !blooms.length ? true : blooms.includes(question.blooms);

    // Filter by library type
    // if custom question has no questionUse value, show always, if has questionUse (because it was authored through L8y directly), filter like other questions
    const filterByLibraryType = !questionUse.length ? true : (question.type === LibraryTypeEnum.User && !question.questionUse) || questionUse.includes(question.questionUse);

    // Filter by grading type
    const filterByGradingType = !gradingType.length ? true : gradingType.includes(question.gradingType);

    // Filter by assignment status (if the question has been assigned to a different assessment don't show it)
    const questionIsAssignedElsewhere = !!editingAssessmentId && assessmentQuestionMaps.find((aqm) => aqm.assessmentId !== editingAssessmentId && aqm.questionId === question.id);
    const filterByAssigned = assignment ? true : !questionIsAssignedElsewhere;

    // Filter by questionType
    let questionInQuestionTypeFilter = true;
    if (!!questionTypes.length) {
      const selectedL8yQuestionTypes: Array<L8yQuestionType> = userFacingQuestionTypes.reduce((acc: Array<L8yQuestionType>, qType) => {
        if (questionTypes.includes(qType.abbreviation)) {
          acc.push(...qType.l8yTypes);
        }
        return acc;
      }, []);
      // if the question has any of the selected question types, it passes the filter
      questionInQuestionTypeFilter = question.questionTypes && !!question.questionTypes.filter((qType) => selectedL8yQuestionTypes.includes(qType)).length;
    }

    return [
      filterByAuthor,
      filterByBlooms,
      filterByLibraryType,
      filterByGradingType,
      filterByAssigned,
      questionInQuestionTypeFilter,
      question.active === YesNo.Yes,
    ].every((val) => val === true);
  });

  const {
    questionsWithCourseLos,
    questionsWithTemplateLos,
    questionsWithCustomLosNotUsedInCourse,
  } = subtractiveFiltering.reduce((acc: { [key: string]: Array<ActiveCombinedQuestion> }, cur) => {
    if (!!cur.courseLearningObjectives.length) {
      acc.questionsWithCourseLos.push(cur);
    } else if (!!cur.learningObjectiveIds && !!cur.learningObjectiveIds.length) {
      acc.questionsWithTemplateLos.push(cur);
    } else {
      acc.questionsWithCustomLosNotUsedInCourse.push(cur);
    }
    return acc;
  }, { questionsWithCourseLos: [], questionsWithTemplateLos: [], questionsWithCustomLosNotUsedInCourse: [] });

  questionsWithCourseLos.sort((a, b) => {
    // just sort by the first Course LO in the list
    const [aClo] = a.courseLearningObjectives;
    const [bClo] = b.courseLearningObjectives;
    return Number(aClo._derived.loDecimalString) - Number(bClo._derived.loDecimalString);
  });
  questionsWithTemplateLos.sort((a, b) => {
    // sort by the first LO in the list, bangs added below because I didn't want to make a special interface for handling the absence of `learningObjectiveIds`
    const [aLoId] = a.learningObjectiveIds;
    const [bLoId] = b.learningObjectiveIds;
    return aLoId - bLoId;
  });
  questionsWithCustomLosNotUsedInCourse.sort((a, b) => a.title.localeCompare(b.title));
  return [
    ...questionsWithCourseLos,
    ...questionsWithTemplateLos,
    ...questionsWithCustomLosNotUsedInCourse,
  ];
};

export default filterQuestions;

import React from 'react';
import PropTypes from 'prop-types';

import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Icon, { IconEnum } from 'shared-components/Icon';
import { MuddyAttemptData } from '../../studentScores.types';
import { PositionEnum } from 'types/common.types';
import './MuddyBar.scss';

interface MuddyBarProps {
  muddyAttemptData: MuddyAttemptData | null
}

function MuddyBar(props: MuddyBarProps) {
  const { muddyAttemptData } = props;
  if (muddyAttemptData === null) {
    return (
      <></>
    );
  }

  const { numerator, denominator, percentage } = muddyAttemptData;
  const muddyText = denominator > 0
    ? `${numerator} of ${denominator} students marked this item as muddy on their first attempt.`
    : 'No students have attempted this item.';

  const width = `${percentage}%`;
  const noDataStyle = !!denominator
    ? ''
    : 'no-data-style';

  return (
    <BetterTooltip
      content={muddyText}
      inModal
      position={PositionEnum.BottomRight}
    >
      <div className="muddy-bar-chart-container" >
        <div className="muddy-percentage-display">
          <Icon which={IconEnum.Muddy} size={16} />
        </div>
        <div className={`muddy-bar-container ${noDataStyle}`}>
          <div className="muddy-bar" style={{ width }}/>
        </div>
        <div className="muddy-percentage-display">
          {!!denominator && <>{percentage.toFixed(0)}%</>}
        </div>
      </div>
    </BetterTooltip>
  );
}

MuddyBar.propTypes = {
  muddyAttemptData: PropTypes.object,
};

export default MuddyBar;

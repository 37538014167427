// Adapted from: https://levelup.gitconnected.com/build-an-accessible-switch-component-with-react-and-typescript-d455a405aaa
import React from 'react';
import PropTypes from 'prop-types';

import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  'data-on'?: string
  'data-off'?: string
  disabled?: boolean
  id: string
  label?: string
  onChange: (checked: boolean) => void
  checked: boolean
  description?: string
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const { disabled } = props;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.checked);
  };

  const labelId = `label-${props.id}`;
  const descriptionId = `description-${props.id}`;

  const labelBy = `${labelId } ${ descriptionId}`;

  return (
    <label htmlFor={props.id} className="toggle-switch">
      <input
        disabled={disabled}
        id={props.id}
        type="checkbox"
        role="switch"
        data-on={props['data-on']}
        checked={props.checked}
        data-off={props['data-off']}
        onChange={onChange}
        aria-checked={props.checked}
        aria-labelledby={labelBy}
      />
      <div className="toggle-switch-labels">
        <span id={labelId}>{props.label || ''}</span>
        {props.description && <p id={descriptionId}>{props.description}</p>}
      </div>
    </label>
  );
};

ToggleSwitch.defaultProps = {
  'data-on': 'ON',
  'data-off': 'OFF',
};

ToggleSwitch.propTypes = {
  'data-on': PropTypes.string,
  'data-off': PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;

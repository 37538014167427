import React from 'react';
import { formatPlural, formatPoints } from 'utils/commonFormattingFunctions';
import logExecutionTime from 'utils/logExecutionTime';
import { renderFeedbackOnValidate } from './l8yRenderMethods';
import ScriptTag from 'shared-components/ScriptTag/ScriptTag';

import apiNext from 'api-next';
import AssessmentTakerActionBar from 'shared-components/AssessmentTakerActionBar/AssessmentTakerActionBar';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import VatFrozenMessage from './VatFrozenMessage';
import { determineFinishButtonState, LearnosityWrap } from './l8yContainerFunctions';
import { AssessmentLocation, LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import {
  AttemptsHash,
  BooleanHash,
  ClarityHash,
  CorrectHash,
  DirectionEnum,
  MultipleAttemptPolicyEnum,
  PointsHash,
  RecapHash,
  VatFrozenHash,
} from 'types/common.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { GradingTypeTag, L8yQuestionType } from 'types/backend/l8y.types';
import { ClarityEnum, StudentAssessmentQuestionAttemptApi, StudentAssessmentQuestionAttemptApiCreateOut } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import {
  ItemsFromL8y,
  L8yContainerEventData,
  L8yContainerEvents,
  L8yEvents,
  LearnosityGetQuestion,
  LearnosityGetResponse,
  LearnosityItemsApi,
  LearnosityItemsApiQuestion,
  LearnosityItemsInit,
  LearnosityItemsObject,
  LearnosityValidateOptions,
  QuestionStatusHash,
} from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { RenderingTypeEnum, AssessmentModeEnum } from 'types/backend/assessmentInit.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTakerController/AssessmentTakerController.types';
import { L8yErrorData } from './LearnosityContainer.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { ValidatedInstructorAttempt } from 'utils/assessmentFunctions';
import { VatHashes } from 'utils/getVatHashesFromSaqa';
import './LearnosityContainer.scss';

export type L8yEventPayload = { type: L8yContainerEvents; data: L8yContainerEventData }
export type L8yExitPayload = VatHashes & { correctQuestionsCount: number }
export type HandleEvents = ({ type, data }: L8yEventPayload) => Promise<void> | void

// The base l8y item, the bare minimum for render, must support BasicQuestionForPreview, AssessmentControllerQuestion, QuestionDatumForSPATL8y
export interface BaseL8yQuestionSkeleton {
  gradingType: GradingTypeTag
  l8yId: string
  type: LibraryTypeEnum
  latestVatStudentAssessmentQuestionAttempt?: StudentAssessmentQuestionAttemptApi | null
}

export interface L8yAssessmentQuestion extends BaseL8yQuestionSkeleton {
  assessmentQuestionId?: number
  assessmentQuestionPoints?: number
  gradingPolicy?: GradingPolicyEnum
}

export interface L8yContainerValidated extends
  Pick<StudentAssessmentQuestionAttemptApi, 'assessmentQuestionId' | 'rawMaxScore' | 'isCorrect' | 'clarity'>,
  Pick<L8yAssessmentQuestion, 'assessmentQuestionPoints'>
{
  score: number
  attemptData: Array<LearnosityGetResponse>
  activeL8yRef: string
  previousAttemptNum: number
  previousPointsEarned: number
  previousLatePointsDeducted: number
}

export type HandleValidated = (data: L8yContainerValidated) => Promise<StudentAssessmentQuestionAttemptApiCreateOut | ValidatedInstructorAttempt | void>


/*
  LearnosityContainer Component
  Intention: Thinnest-possible layer to deal with eventing/rendering of L8y assessment rectangles. Should be used across various assessment types, with most style/logic
  within the parent component.
  Notes: This component is deliberately using the old-school component class format because of the requirements/limitations of Learnosity
  we want to limit re-renders and persist the ItemsAPI to `this` for events
  Also, it's not a .tsx component yet because trying to do TypeScript hurdles whilst figuring out L8y was a bit much, eventually this should, like all new components, be converted to TS
*/

export interface L8yProps {
  activityId: string
  assessmentMode: AssessmentModeEnum
  assessmentType: AssessTypeEnum | null
  attemptLimit?: number | null
  attemptPolicy?: MultipleAttemptPolicyEnum
  disableClarity?: boolean
  handleEvents: HandleEvents
  handleValidated?: HandleValidated
  handleFinished?: (exitPayload: L8yExitPayload) => void
  onL8yError?: (error: L8yErrorData) => void
  initAttemptsHash?: AttemptsHash
  initClarityHash?: ClarityHash
  initCorrectHash?: CorrectHash
  initEverCorrectHash?: CorrectHash
  initLatePointsDeductedHash?: PointsHash
  initPointsHash?: PointsHash
  initRecapHash?: RecapHash
  initVatFrozenHash?: VatFrozenHash
  initState?: L8ySessionState
  inReviewMode: boolean
  isAfterLate?: boolean
  isInstructor?: boolean
  items: Array<BaseL8yQuestionSkeleton>
  l8yBoxClassName?: string
  l8ySessionId?: string
  location: AssessmentLocation
  name: string
  questionData: Array<L8yAssessmentQuestion>
  renderingType: RenderingTypeEnum
  renderItemNav?: (
    activeL8yRef: string,
    handleItemNav: (l8yId: string) => void,
    questionStatusHash: QuestionStatusHash
  ) => React.ReactNode
  studentAssessmentId?: number
  targetL8yId?: string
  todoQuestionL8yIds?: Array<string>
  userId: string
}

interface L8yState {
  activeAssessmentQuestion: L8yAssessmentQuestion | null
  activeItemId: string | null
  activeItemIndex: number | null
  activeL8yRef: string | null
  attemptsHash: AttemptsHash
  clarityHash: ClarityHash
  correctHash: CorrectHash
  currentStage: AssessmentTakerQuestionStage
  enableValidate: boolean
  everCorrectHash: CorrectHash
  inputChanged: boolean
  itemHasAllORQsHash: BooleanHash
  itemQuestions: Array<LearnosityGetQuestion>
  l8yReady: boolean
  latePointsDeductedHash: PointsHash
  pointsHash: PointsHash
  questionIds: Array<string>
  recapHash: RecapHash
  vatFrozenHash: VatFrozenHash
}

interface QuestionMethodsHash { [key: string]: LearnosityItemsApiQuestion }

export default class LearnosityContainer extends React.Component<L8yProps, L8yState> {
  startTime: number;
  activeQuestionMethods: Array<LearnosityItemsApiQuestion>;
  lastValidation: number;
  isVat: boolean;
  isPreview: boolean;
  isStudyPath: boolean;
  l8yItemList: Array<string>;
  hideValidationUI: boolean;
  aqPointsHash: PointsHash;
  surveyItemsHash: BooleanHash;
  l8yItems!: {
    init: LearnosityItemsInit
  };
  itemsAPI!: LearnosityItemsApi;
  itemsObject!: LearnosityItemsObject;
  itemsFromL8y!: { [key: string]: ItemsFromL8y };

  constructor(props: L8yProps) {
    super(props);
    this.state = {
      activeAssessmentQuestion: null,
      activeItemId: null,
      activeItemIndex: null,
      activeL8yRef: props.targetL8yId || null,
      attemptsHash: props.initAttemptsHash || {},
      clarityHash: props.initClarityHash || {},
      correctHash: props.initCorrectHash || {},
      everCorrectHash: props.initEverCorrectHash || {},
      itemHasAllORQsHash: {},
      latePointsDeductedHash: props.initLatePointsDeductedHash || {},
      recapHash: props.initRecapHash || {},
      vatFrozenHash: props.initVatFrozenHash || {},
      enableValidate: false,
      inputChanged: false,
      itemQuestions: [],
      l8yReady: false,
      pointsHash: props.initPointsHash || {},
      questionIds: [],
      currentStage: AssessmentTakerQuestionStage.INIT,
    };
    console.debug('constructor targetL8yId', props.targetL8yId);
    this.startTime = performance.now();
    this.activeQuestionMethods = [];
    this.lastValidation = 0;
    this.isVat = props.location === AssessmentLocation.AT;
    this.isPreview = props.location === AssessmentLocation.Preview;
    this.isStudyPath = [AssessmentLocation.SP, AssessmentLocation.SPPTAT].includes(props.location);
    // persist initial list of l8y items because changing them later doesn't affect l8y and causes other unintended side effects
    this.l8yItemList = props.items.map(({ l8yId }) => l8yId);
    this.hideValidationUI = props.assessmentType === AssessTypeEnum.PracticeTest && props.location === AssessmentLocation.AT;
    // get hash of total points available per question
    this.aqPointsHash = props.questionData.reduce((acc: PointsHash, questionDatum) => {
      const { assessmentQuestionPoints, l8yId } = questionDatum;
      if (!!assessmentQuestionPoints) {
        return {
          ...acc,
          [l8yId]: assessmentQuestionPoints,
        };
      }
      return acc;
    }, {});
    this.surveyItemsHash = props.questionData.reduce((acc: BooleanHash, { l8yId, gradingType }) => {
      return {
        ...acc,
        [l8yId]: gradingType === GradingTypeTag.Survey,
      };
    }, {});
  }

  componentDidUpdate(prevProps: L8yProps) {
    if (!!this.itemsObject && !!this.props.targetL8yId && prevProps.targetL8yId !== this.props.targetL8yId) {
      console.debug(`componentDidUpdate goto ${this.props.targetL8yId} from ${prevProps.targetL8yId}`);
      this.itemsObject.goto(this.props.targetL8yId);
    }
  }

  transition = (nextStage: AssessmentTakerQuestionStage) => {
    const { currentStage } = this.state;
    console.debug(`:::: L8y stage transition ${currentStage} => ${nextStage}`);
    this.setState({ currentStage: nextStage });
  };

  handleItemLoad = () => {
    try {
      const { handleEvents } = this.props;
      const { itemQuestions, vatFrozenHash, activeL8yRef } = this.state;
      // TODO Cleanup: This doesn't return the same data as it used to, this is always an array of one questionId
      const questionIds = itemQuestions.map(q => q.response_id);
      this.setState({ questionIds });

      if (!activeL8yRef) {
        throw new Error('activeL8yRef not defined during handleItemLoad');
      }

      const questions = questionIds.reduce((acc: QuestionMethodsHash, questionId) => {
        return {
          ...acc,
          [questionId]: this.itemsAPI.question(questionId),
        };
      }, {} as QuestionMethodsHash);

      // reset active question methods
      this.activeQuestionMethods = [];
      const vatFrozen = this.isVat && vatFrozenHash[activeL8yRef] === YesNo.Yes;
      if (vatFrozen) {
        this.setState({ enableValidate: false });
      }
      const isSurveyItem = this.surveyItemsHash[activeL8yRef];

      Object.values(questions).forEach((q, i) => {
        // Store methods for each question in an array for use elsewhere in the component
        this.activeQuestionMethods[i] = q;
        if (vatFrozen) {
          q.disable();
        }
        // fires when question inputs change
        q.on('changed', () => {
          this.handleInputChanged();
          handleEvents({ type: L8yContainerEvents.QUESTION_CHANGED, data: { activeL8yRef } });
          const l8yQuestionData = q.getQuestion();
          if ([
            L8yQuestionType.MultipleChoiceMultipleSelect,
            L8yQuestionType.EssayRichText,
            L8yQuestionType.ClozeDropDown,
            L8yQuestionType.LabelImageDropDown,
            L8yQuestionType.Hotspot,
            L8yQuestionType.Sequence,
            L8yQuestionType.OrderList,
            L8yQuestionType.Sorting,
            L8yQuestionType.MatchList,
            L8yQuestionType.LabelImageDragAndDrop,
          ].includes(l8yQuestionData.type)) {
            this.removeDistractor(l8yQuestionData.response_id);
          }
        });
        // fires on Check Answer
        q.on('validated', async () => {
          if (this.hideValidationUI || isSurveyItem) {
            q.resetValidationUI();
          } else {
            const l8yQuestionData = q.getQuestion();
            const isValid = q.isValid();
            const responseLevel = q.mapValidationMetadata('distractor_rationale_response_level');
            const { value: responseValue } = q.getResponse();
            const distractorRationale = l8yQuestionData.metadata.distractor_rationale;
            const feedbackData = {
              distractorRationale,
              isValid,
              questionData: l8yQuestionData,
              responseLevel,
              responseValue,
            };
            if (l8yQuestionData.type === L8yQuestionType.MultipleChoiceMultipleSelect && !!l8yQuestionData.multiple_responses) {
              q.resetValidationUI();
            }
            renderFeedbackOnValidate(feedbackData);
          }
          await this.handleValidateAllQuestionsInItem(questions);
        });
      });
    } catch (err) {
      console.error('err', err, this.itemsAPI.questions());
    }
  };

  removeDistractor = (id: string) => {
    const distractorSelector = document.querySelector(`[id='#${id}_distractor']`);
    if (!!distractorSelector) {
      distractorSelector.remove();
    }
    const questionDiv = document.querySelector(`[id='${id}']`);
    if (questionDiv) {
      const orWrapper = questionDiv.querySelector('.lrn_response_input_wrapper');
      if (orWrapper) {
        orWrapper.className = 'lrn_response_input_wrapper';
      }
    }
  };

  showOpenResponseAnswers = (responseId: string, sampleAnswer: string, showFirstAnswer: boolean, qIndex?: number) => {
    const { attemptPolicy } = this.props;
    const { activeL8yRef } = this.state;
    const isSurveyItem = !!activeL8yRef && this.surveyItemsHash[activeL8yRef];
    if (!isSurveyItem) {
      const questionDiv = document.querySelector(`[id='${responseId}']`);
      if (questionDiv) {
        const wrapper = questionDiv.querySelector('.lrn_response_input_wrapper');
        if (!!wrapper) {
          wrapper.className = 'lrn_response_input_wrapper lrn_correct lrn_complete';
        }
      }
      if (!document.querySelector(`[id='#${responseId}_distractor']`)) {
        let firstAnswerContent = '';
        if (showFirstAnswer) {
          const { activeAssessmentQuestion } = this.state;
          const { latestVatStudentAssessmentQuestionAttempt } = activeAssessmentQuestion || {};
          let firstAnswer = null;
          if (!!latestVatStudentAssessmentQuestionAttempt && !!qIndex) {
            const { attemptData } = latestVatStudentAssessmentQuestionAttempt;
            firstAnswer = attemptData[qIndex].value;
          }
          firstAnswerContent = `
            <div class="lrn_distractor_rationale_list lrn_distractor_rationale_correct lrn_distractor_rationale_first" aria-label="distractor rationale per response">
              <div class="lrn_distractor_rationale_list_title">Your First Answer – You gave this answer when first taking the Practice Test.</div>
              <div class="lrn_distractor_rationale">
                <div class="lrn_distractor_rationale_content">${firstAnswer || '<i>No answer submitted</i>'}</div>
              </div>
            </div>
          `;
        }
        let sampleAnswerSuffix = '';
        if (this.isPreview) {
          sampleAnswerSuffix = '<br>Note to Instructors – Students earn full points upon submission of open-response questions, regardless of grading policy. Instructor grading of open-response questions is not available.';
        } else if (!!attemptPolicy && attemptPolicy !== MultipleAttemptPolicyEnum.NotForPoints) {
          sampleAnswerSuffix = '<br>You have earned full points for attempting this question.';
        }
        const template = `
          <div id="#${responseId}_distractor" class="lrn_distractor_rationale_wrapper" role="region" aria-label="distractor rationale">
            ${firstAnswerContent}
            <div class="lrn_distractor_rationale_list lrn_distractor_rationale_correct lrn_distractor_rationale_complete" aria-label="distractor rationale per response complete">
              <div class="lrn_distractor_rationale_list_title">Sample Answer – Assess your understanding by comparing the sample answer to your own.${sampleAnswerSuffix}</div>
              <div class="lrn_distractor_rationale">
                <div class="lrn_distractor_rationale_content">${sampleAnswer}</div>
              </div>
            </div>
          </div>
        `;
        document.querySelector(`[id='${responseId}']`)?.insertAdjacentHTML('beforeend', template);
      }
    }
  };

  validateOpenResponse = (questionMethods: LearnosityItemsApiQuestion, l8yQuestionData: LearnosityGetQuestion, qIndex: number) => {
    const { metadata: { sample_answer = '' }, response_id: qid } = l8yQuestionData;
    const { location } = this.props;
    if (location === AssessmentLocation.SPPTAT) {
      this.showOpenResponseAnswers(qid, sample_answer, true, qIndex);
    } else {
      if (!this.hideValidationUI) {
        if (questionMethods.isAttempted() || this.isPreview) {
          // if attempted, show the key below the OR box
          this.showOpenResponseAnswers(qid, sample_answer, false);
        }
      }
    }
    questionMethods.trigger('validated');
  };

  validateQuestionlessItem = async () => {
    const { isInstructor } = this.props;
    const now = Date.now();
    if ((now - this.lastValidation) > 1000) {
      const itemMaxScore = 1;
      const itemScore = 1;
      const isCorrect = YesNo.Yes;
      // Don't do all the extra item validation stuff in Preview mode
      if (!this.isPreview) {
        await this.handleItemValidated({ itemScore, itemMaxScore, attemptDataArray: [], isCorrect });
        // save the session
        if (!this.isStudyPath && !isInstructor) {
          await this.itemsAPI.save();
        }
      }
    } else {
      console.warn('validate debounced', now, this.lastValidation);
    }
  };

  handleValidateAllQuestionsInItem = async (questions: QuestionMethodsHash) => {
    const { isInstructor } = this.props;
    const { activeAssessmentQuestion } = this.state;
    const now = Date.now();
    if (!!activeAssessmentQuestion && (now - this.lastValidation) > 1000) {
      let itemMaxScore = 0;
      let itemScore = 0;
      const attemptDataArray: Array<LearnosityGetResponse> = [];
      const isCorrectArray: Array<YesNo> = [];
      if (activeAssessmentQuestion.gradingType === GradingTypeTag.Assessment) {
        for (const q in questions) {
          const qScore = questions[q].getScore();
          const { max_score } = qScore;
          let { score } = qScore;
          const response: LearnosityGetResponse = questions[q].getResponse();
          if (questions[q].getQuestion().type === L8yQuestionType.EssayRichText) {
            if (questions[q].isAttempted()) {
              isCorrectArray.push(YesNo.Yes);
              if (!score) {
                // give them full points even if they deleted their answer before submitting
                score = max_score;
              }
            } else {
              isCorrectArray.push(YesNo.No);
            }
          } else {
            isCorrectArray.push(questions[q].isValid() ? YesNo.Yes : YesNo.No);
          }
          attemptDataArray.push(response);
          itemMaxScore += max_score;
          itemScore += score;
        }
      } else {
        // survey item: correct and full credit for attempting
        for (const q in questions) {
          const max_score = 1;
          const response = questions[q].getResponse();
          isCorrectArray.push(YesNo.Yes);
          attemptDataArray.push(response);
          itemMaxScore += max_score;
          itemScore += max_score;
        }
      }
      const isCorrect = isCorrectArray.some(c => [YesNo.No, null].includes(c)) ? YesNo.No : YesNo.Yes;
      // Don't do all the extra item validation stuff in Preview mode
      if (!this.isPreview) {
        await this.handleItemValidated({ itemScore, itemMaxScore, attemptDataArray, isCorrect });
        // save the session
        if (!this.isStudyPath && !isInstructor) {
          await this.itemsAPI.save();
        }
      }
    } else {
      console.warn('validate debounced', now, this.lastValidation);
    }
  };

  handleInputChanged = () => {
    const { attemptsHash, clarityHash, activeL8yRef } = this.state;
    const { attemptLimit } = this.props;
    if (!activeL8yRef) {
      throw new Error('activeL8yRef not defined in handleInputChanged');
    }
    const currentItemAttempts = attemptsHash[activeL8yRef] || 0;
    this.setState({ inputChanged: true });

    const itemsAPIGotItems = this.itemsAPI.getItems();
    const itemQuestions = itemsAPIGotItems[activeL8yRef].questions;
    const responseIds = itemQuestions.map(q => q.response_id);
    const allQuestionPartsAnswered = responseIds.every(id => this.itemsAPI.question(id).isAttempted());

    // Always allow show validate if attemptLimit is null
    if (attemptLimit === null || (!!attemptLimit && (attemptLimit - currentItemAttempts) > 0)) {
      // enable validate if all parts of question have been answered and muddy clear selected or survey item
      if (allQuestionPartsAnswered && (this.surveyItemsHash[activeL8yRef] || !!clarityHash[activeL8yRef])) {
        this.setState({ enableValidate: true });
      }
    }
    this.transition(AssessmentTakerQuestionStage.ANSWER_CHANGED);
  };

  handleItemValidated = async ({
    itemScore,
    itemMaxScore,
    attemptDataArray,
    isCorrect,
  }: {
    itemScore: number
    itemMaxScore: number
    attemptDataArray: Array<LearnosityGetResponse>
    isCorrect: YesNo
  }) => {
    this.lastValidation = Date.now();
    const {
      assessmentType,
      attemptLimit,
      handleValidated,
      isAfterLate,
      questionData = [],
    } = this.props;
    const {
      activeL8yRef,
      attemptsHash,
      clarityHash,
      correctHash,
      everCorrectHash,
      latePointsDeductedHash,
      pointsHash,
      recapHash,
      vatFrozenHash,
    } = this.state;

    if (!activeL8yRef || !handleValidated) {
      throw new Error('activeL8yRef or handleValidated not defined');
    }

    // if clarity is not set (when not mandated by the VAT), pass null to clarity
    const activeItemClarity = clarityHash[activeL8yRef] || null;
    const activeItemAttempts = attemptsHash[activeL8yRef] || 0;
    const activeItemLatePointsDeducted = latePointsDeductedHash[activeL8yRef] || 0;
    const activeItemPoints = pointsHash[activeL8yRef] || 0;
    const activeItemEverCorrect = everCorrectHash[activeL8yRef] || YesNo.No;

    const isHwOrPt = assessmentType && [AssessTypeEnum.Homework, AssessTypeEnum.PracticeTest].includes(assessmentType);
    const hwPTAndOutOfAttempts = isHwOrPt && attemptLimit && (activeItemAttempts + 1) >= attemptLimit;
    const correctness = isCorrect === YesNo.Yes;
    const isVatFrozen = hwPTAndOutOfAttempts || correctness;
    if (this.isVat && isVatFrozen) {
      for (const active of this.activeQuestionMethods) {
        active.disable();
      }
    }
    const { assessmentQuestionId, assessmentQuestionPoints } = questionData.find((q) => q.l8yId === activeL8yRef) as L8yAssessmentQuestion;
    if (!assessmentQuestionId) {
      console.error('assessmentQuestionId not found in questionData');
      return;
    }
    const data = {
      assessmentQuestionId,
      assessmentQuestionPoints,
      score: itemScore,
      rawMaxScore: itemMaxScore,
      attemptData: attemptDataArray,
      isCorrect,
      activeL8yRef,
      clarity: activeItemClarity,
      previousAttemptNum: activeItemAttempts,
      previousPointsEarned: activeItemPoints,
      previousLatePointsDeducted: activeItemLatePointsDeducted,
    };
    const attempt = await handleValidated(data);
    if (!attempt) {
      console.error(`handleValidated FAIL ${data}`);
      return;
    }
    const { gradedAdjustedPointsEarned, freePlay, vatFrozen, latePointsDeducted } = attempt;
    const updatedAttemptsHash = {
      ...attemptsHash,
      [activeL8yRef]: activeItemAttempts + 1,
    };

    const updatedVatFrozenHash = {
      ...vatFrozenHash,
      [activeL8yRef]: vatFrozen || YesNo.No,
    };
    const updatedCorrectHash = {
      ...correctHash,
      [activeL8yRef]: isCorrect,
    };
    const updatedEverCorrectHash = {
      ...everCorrectHash,
      [activeL8yRef]: activeItemEverCorrect === YesNo.No && correctness ? YesNo.Yes : activeItemEverCorrect,
    };
    const updatedLatePointsDeductedHash = {
      ...latePointsDeductedHash,
      [activeL8yRef]: latePointsDeducted || null, // this can potentially be undefined, default to null for safety
    };
    const updatedPointsHash = {
      ...pointsHash,
      [activeL8yRef]: gradedAdjustedPointsEarned || null,
    };
    const currentQuestionCanRecap = freePlay === YesNo.No;
    const updatedRecapHash = {
      ...recapHash,
      [activeL8yRef]: currentQuestionCanRecap,
    };
    this.setState({
      attemptsHash: updatedAttemptsHash,
      correctHash: updatedCorrectHash,
      enableValidate: false,
      everCorrectHash: updatedEverCorrectHash,
      latePointsDeductedHash: updatedLatePointsDeductedHash,
      pointsHash: updatedPointsHash,
      // don't update recap icons if after late
      recapHash: !isAfterLate ? updatedRecapHash : {},
      vatFrozenHash: updatedVatFrozenHash,
    });
    const nextStage = isCorrect === YesNo.Yes ? AssessmentTakerQuestionStage.VALIDATED_CORRECT : AssessmentTakerQuestionStage.VALIDATED_INCORRECT;
    this.transition(nextStage);
  };

  handleItemChanged = async (itemIndex: number) => {
    const { handleEvents, questionData, assessmentType, location } = this.props;
    const { clarityHash, l8yReady } = this.state;
    const activeL8yRef = this.l8yItemList[itemIndex];
    console.debug('handleItemChanged', activeL8yRef, itemIndex, this.l8yItemList);
    const activeItemClarity = clarityHash[activeL8yRef];
    const itemsAPIGotItems = this.itemsAPI.getItems();
    const itemQuestions = itemsAPIGotItems[activeL8yRef].questions;
    const activeAssessmentQuestion = questionData.find((q) => q.l8yId === activeL8yRef) as L8yAssessmentQuestion;
    this.setState({
      itemQuestions,
      activeAssessmentQuestion,
    });
    const questionIds = itemQuestions.map(q => q.response_id);
    // Had to re-implement this because the way of retrieving activeItemId wasn't working, see above comment ~L115
    // TODO: Revisit the whole question array issue, there's probably another way to get activeItemId
    const itemsAPIQuestions = this.itemsAPI.questions();
    const questionIdsOG = Object.keys(itemsAPIQuestions);
    const activeItemId = questionIdsOG[itemIndex];

    // don't send ITEM_CHANGED before l8y is ready, this might break some things
    if (l8yReady) {
      await handleEvents({ type: L8yContainerEvents.ITEM_CHANGED, data: { activeL8yRef } });
    }

    await handleEvents({ type: L8yContainerEvents.QUESTIONS_LOADED, data: { activeL8yRef, questionIds } });

    if (location === AssessmentLocation.SPPTAT) {
      itemQuestions.forEach((cur, qIndex) => {
        if (cur.type === L8yQuestionType.EssayRichText) {
          const { response_id, metadata: { sample_answer = '' } } = cur;
          this.showOpenResponseAnswers(response_id, sample_answer, true, qIndex);
        }
      });
    }

    const isSurveyItem = this.surveyItemsHash[activeL8yRef];
    let enableValidate = questionIds.every(id => this.itemsAPI.question(id).isAttempted());
    if (assessmentType && ([AssessTypeEnum.Homework, AssessTypeEnum.Preclass, AssessTypeEnum.Readiness, AssessTypeEnum.PracticeTest].includes(assessmentType) || !questionIds.length)
      && activeItemClarity === undefined && !isSurveyItem) {
      // don't enable validate if clarity not selected unless it's a survey item
      enableValidate = false;
    }

    this.setState({
      activeItemId,
      activeItemIndex: itemIndex,
      activeL8yRef,
      enableValidate,
      inputChanged: false,
      questionIds,
    });
    this.transition(AssessmentTakerQuestionStage.INIT);
  };

  handleFinished = async () => {
    const { handleFinished, isInstructor, studentAssessmentId } = this.props;
    const {
      clarityHash,
      attemptsHash,
      correctHash,
      everCorrectHash,
      latePointsDeductedHash,
      pointsHash,
      recapHash,
      vatFrozenHash,
    } = this.state;
    let correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas> = [];
    if (!isInstructor && studentAssessmentId) {
      const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestionsByStudentAssessmentId(studentAssessmentId) as Array<StudentAssessmentQuestionApiWithSaqas>;
      correctQuestionsArray = studentAssessmentQuestions.filter(s => s.gradedStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
    }

    if (handleFinished) {
      handleFinished({
        attemptsHash,
        clarityHash,
        correctHash,
        correctQuestionsCount: correctQuestionsArray.length,
        everCorrectHash,
        latePointsDeductedHash,
        pointsHash,
        recapHash,
        vatFrozenHash,
      });
    }
  };

  handleReady = () => {
    this.itemsObject = this.itemsAPI.items();
    this.itemsFromL8y = this.itemsAPI.getItems();
    const { items, targetL8yId } = this.props;
    const newItemHasAllORQsHash = items.reduce((acc, { l8yId }) => {
      const itemFromL8y = this.itemsFromL8y[l8yId];
      if (!itemFromL8y) {
        console.error(`l8yId ${l8yId} not found in itemsFromL8y`, this.itemsFromL8y);
      }
      const allORQs = !itemFromL8y.questions.some(({ type }) => type !== L8yQuestionType.EssayRichText);
      return {
        ...acc,
        [l8yId]: allORQs,
      };
    }, {});
    this.setState({
      l8yReady: true,
      itemHasAllORQsHash: newItemHasAllORQsHash,
    });

    if (!!targetL8yId) {
      this.itemsObject.goto(targetL8yId);
    }
  };

  handleLoad = async () => {
    const {
      activityId,
      assessmentMode,
      assessmentType,
      handleEvents,
      initState,
      isInstructor,
      items,
      l8ySessionId,
      location,
      name,
      renderingType,
      userId,
    } = this.props;
    const data = await apiNext.createSignedLearnosityRequest({
      activityId: isInstructor ? 'instructor-view' : activityId,
      assessmentMode,
      assessmentType,
      initState,
      items,
      l8ySessionId,
      location,
      name,
      renderingType,
      userId,
    });
    console.debug('getSignedLearnosityRequest data', data);
    this.l8yItems = await (window as any).LearnosityItems;
    this.itemsAPI = await this.l8yItems.init(data, 'testregion', {
      errorListener: this.props.onL8yError,
      readyListener: this.handleReady,
      customUnload: () => {
        console.debug('beforeunload event has been triggered');
        return false;
      },
    });
    this.itemsAPI.on(L8yEvents.ITEM_LOAD, this.handleItemLoad);
    this.itemsAPI.on(L8yEvents.ITEM_CHANGED, this.handleItemChanged);
    this.itemsAPI.on(L8yEvents.TEST_FINISHED_SUBMIT, this.handleFinished);

    this.itemsAPI.on(L8yEvents.ITEM_GOTO, (e) => handleEvents({ type: L8yContainerEvents.ITEM_GOTO, data: e }));
    this.itemsAPI.on(L8yEvents.ITEM_UNLOAD, (e) => handleEvents({ type: L8yContainerEvents.ITEM_UNLOAD, data: e }));
    this.itemsAPI.on(L8yEvents.ITEM_SETATTEMPTEDRESPONSE, (e) => console.debug('item:setAttemptedResponse', e));
  };

  triggerValidate = async (itemIndex: number) => {
    if (itemIndex > -1) {
      if (!this.activeQuestionMethods.length) {
        await this.validateQuestionlessItem();
      } else {
        const { activeAssessmentQuestion } = this.state;
        if (activeAssessmentQuestion?.gradingType === GradingTypeTag.Assessment) {
          this.activeQuestionMethods.forEach((active, qIndex) => {
            const ques = active.getQuestion();
            if (ques.type === L8yQuestionType.EssayRichText) {
              this.validateOpenResponse(active, ques, qIndex);
            } else {
              const validateOpts: LearnosityValidateOptions = {
                showCorrectAnswers: this.isPreview,
              };
              if ((ques.type === L8yQuestionType.MultipleChoiceMultipleSelect && !ques.multiple_responses)
                || [L8yQuestionType.ClozeDropDown, L8yQuestionType.LabelImageDragAndDrop].includes(ques.type)
              ) {
                // don't let L8y show the feedback
                validateOpts.showDistractorRationale = 'never';
              }
              active.validate(validateOpts);
            }
          });
        } else {
          // survey item
          this.activeQuestionMethods.forEach((active) => {
            active.trigger('validated');
          });
        }
      }
    }
  };

  handleClarity = (clarity: ClarityEnum, l8yRef: string) => {
    const { handleEvents } = this.props;
    const { clarityHash, questionIds } = this.state;
    const newClarityHash = {
      ...clarityHash,
      [l8yRef]: clarity,
    };
    // update parent clarityHash on clarity toggle
    handleEvents({ type: L8yContainerEvents.HANDLE_CLARITY, data: { activeL8yRef: l8yRef, clarityHash: newClarityHash } });
    // When clarity is set only enable validate if all parts of the question have been attempted
    const questions = questionIds.reduce((acc: QuestionMethodsHash, q) => {
      return {
        ...acc,
        [q]: this.itemsAPI.question(q),
      };
    }, {});

    this.setState({
      enableValidate: questionIds.every(id => questions[id].isAttempted()),
      clarityHash: newClarityHash,
    });
    this.transition(AssessmentTakerQuestionStage.CLARITY_SELECTED);
  };

  handleActionBarNav = (navDirection: DirectionEnum) => {
    if (navDirection === DirectionEnum.Next) {
      this.itemsObject.next();
    } else if (navDirection === DirectionEnum.Prev) {
      this.itemsObject.previous();
    }
  };

  handleItemNav = (l8yRef: string) => {
    this.setState({ activeL8yRef: l8yRef });
  };
  render() {
    const {
      activeAssessmentQuestion,
      activeItemId,
      activeItemIndex,
      activeL8yRef,
      attemptsHash,
      clarityHash,
      correctHash,
      currentStage,
      enableValidate,
      everCorrectHash,
      itemHasAllORQsHash,
      l8yReady,
      pointsHash,
      questionIds,
      recapHash,
      vatFrozenHash,
    } = this.state;
    const {
      assessmentType,
      attemptLimit,
      attemptPolicy,
      disableClarity,
      inReviewMode,
      isAfterLate,
      items,
      l8yBoxClassName = 'col-xs-12',
      location,
      renderItemNav,
      todoQuestionL8yIds,
    } = this.props;

    const showRexBanner = assessmentType === AssessTypeEnum.Readiness && location === AssessmentLocation.REX && attemptPolicy !== MultipleAttemptPolicyEnum.NotForPoints && isAfterLate;
    const showItemNav = typeof renderItemNav === 'function';
    const showRecap = assessmentType !== AssessTypeEnum.Readiness && !!activeL8yRef && recapHash[activeL8yRef] === true;
    // Get hash of l8yIds with clarity and isCorrect data for displaying nav menu icons
    const questionStatusHash = items.reduce((acc: QuestionStatusHash, { l8yId }: BaseL8yQuestionSkeleton) => {
      const isCorrect = correctHash[l8yId];
      const allORQs = itemHasAllORQsHash[l8yId];
      const clarity = clarityHash[l8yId];
      const canRecap = recapHash[l8yId];
      const isSurveyItem = this.surveyItemsHash[l8yId];
      return {
        ...acc,
        [l8yId]: {
          isCorrect,
          allORQs,
          clarity,
          canRecap,
          isSurveyItem,
        },
      };
    }, {});

    const notForPoints = attemptPolicy === MultipleAttemptPolicyEnum.NotForPoints || activeAssessmentQuestion?.gradingPolicy === GradingPolicyEnum.NoPoints;
    const showPointsOverlay = !this.isPreview
      && l8yReady
      && !(assessmentType === AssessTypeEnum.PracticeTest && !inReviewMode)
      && !notForPoints;
    const pointsHashValue = activeL8yRef && pointsHash[activeL8yRef];
    const currentPoints = typeof pointsHashValue === 'number' ? formatPoints(pointsHashValue).replace(/\.00$/, '') : 0;
    const currentPointsAvailable = activeAssessmentQuestion && activeAssessmentQuestion.assessmentQuestionPoints ? activeAssessmentQuestion.assessmentQuestionPoints : 0;
    const vatFrozen = this.isVat && !!activeL8yRef && vatFrozenHash[activeL8yRef] === YesNo.Yes;
    const allQuestionsFrozenOrAttempted = !this.l8yItemList.some(item => vatFrozenHash[item] === YesNo.No && attemptsHash[item] === undefined);
    const attribution: string | false = !this.isPreview && activeAssessmentQuestion?.type === LibraryTypeEnum.User ? 'This question was created or adapted by your instructor.' : false;

    const { showFinish, enableFinish } = determineFinishButtonState(this.isStudyPath, {
      activeL8yRef,
      questionStatusHash,
      todoQuestionL8yIds,
      allQuestionsFrozenOrAttempted,
    });

    const questionItems = questionIds ? questionIds.length : 0;

    const activeL8yHashKey = activeAssessmentQuestion?.l8yId || '';

    const currentQuestionIsCorrect = correctHash[activeL8yHashKey] || undefined;
    const currentQuestionEverCorrect = everCorrectHash[activeL8yHashKey] || YesNo.No;

    const { allORQs, isSurveyItem } = questionStatusHash[activeL8yHashKey] || {};

    const currentItemContainsOnlyOR = allORQs || undefined;
    const currentItemIsSurveyItem = isSurveyItem || undefined;
    const currentItemIsAttempted = currentItemContainsOnlyOR || currentItemIsSurveyItem;

    if (l8yReady && this.startTime > 0) {
      logExecutionTime(this.startTime, 'LearnosityContainer render, l8yReady=true');
      this.startTime = 0; //reset the startTime to avoid logging multiple times
    }

    return (
      <LearnosityWrap
        id="learnosity-wrap"
        questionStatusHash={questionStatusHash}
        assessmentType={assessmentType}
        inReviewMode={inReviewMode}
        questionItems={questionItems > 1 ? `This question has ${questionItems} parts.` : null}
        pointsString={showPointsOverlay ? `${currentPoints}/${currentPointsAvailable} ${formatPlural('point', currentPointsAvailable)} earned` : null}
        attribution={attribution}
      >
        <ScriptTag src="https://items.learnosity.com/?v2023.2.LTS" async onLoad={() => this.handleLoad()} />
        <div className="learnosity-item__wrap" data-ready={l8yReady} data-assesstype={assessmentType} data-location={location}>
          {(vatFrozen || showRexBanner) && (
            <VatFrozenMessage
              assessType={assessmentType}
              attemptPolicy={attemptPolicy}
              currentItemIsAttempted={currentItemIsAttempted}
              currentQuestionEverCorrect={currentQuestionEverCorrect}
              currentQuestionIsCorrect={currentQuestionIsCorrect}
              isAfterLate={isAfterLate}
            />
          )}
          <div className="row reverse learnosity-row">
            {showItemNav && l8yReady && activeL8yRef && renderItemNav(activeL8yRef, this.handleItemNav, questionStatusHash)}
            <div className={l8yBoxClassName}>
              <div className="learnosity-box">
                {/* This is the div where learnosity is injected */}
                <div className="learnosity-item" data-reference="testregion" id="testregion">
                  {!l8yReady && <LoadingSpinner />}
                </div>
              </div>
            </div>
          </div>
          {l8yReady && activeL8yRef && (
            <AssessmentTakerActionBar
              activeL8yRef={activeL8yRef}
              assessmentType={assessmentType}
              attemptLimit={attemptLimit}
              attemptsHash={attemptsHash}
              clarityHash={clarityHash}
              currentStage={currentStage}
              disableClarity={disableClarity}
              enableFinish={enableFinish}
              enableValidate={enableValidate}
              handleClarity={(clarity, l8yRef) => this.handleClarity(clarity, l8yRef)}
              handleFinish={() => this.handleFinished()}
              handleNav={(direction) => this.handleActionBarNav(direction)}
              handleValidate={() => this.triggerValidate(activeItemIndex as number)}
              itemHasQuestions={!!questionIds.length}
              l8yRefArray={this.l8yItemList}
              location={location}
              isStudyPath={this.isStudyPath}
              showRecap={showRecap}
              showFinish={showFinish}
              surveyItemsHash={this.surveyItemsHash}
              vatFrozen={vatFrozen}
            />
          )}
          {/* This hidden div is used so Cypress knows how to find the active question div */}
          <span className="activeItemId" style={{ visibility: 'hidden' }}>{activeItemId}</span>
        </div>
      </LearnosityWrap>
    );
  }
}

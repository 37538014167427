import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { baseApi } from 'store/api/baseApi';
import activeSlice from 'store/slices/active';
import adminUserSlice from 'store/slices/adminUser';
import enrollmentsSlice from 'store/slices/enrollments';
import passiveSlice from 'store/slices/passive';
import stateSlice from 'store/slices/state';
import storage from 'redux-persist/lib/storage';
import userSlice from 'store/slices/user';
import { Store } from 'types/store.types';

const loggerWithOptions = createLogger({ level: 'debug' });

const allReducers = combineReducers({
  active: activeSlice.reducer,
  adminUser: adminUserSlice.reducer,
  enrollments: enrollmentsSlice.reducer,
  passive: passiveSlice.reducer,
  state: stateSlice.reducer,
  user: userSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const createdStore = configureStore({
  reducer: persistReducer({
    key: 'root',
    version: 1,
    blacklist: ['active'],
    storage,
  }, allReducers),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(thunkMiddleware, loggerWithOptions, baseApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
export type RootState = ReturnType<typeof createdStore.getState>;
export type AppDispatch = typeof createdStore.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<Store> = useSelector;
export const persistor = persistStore(createdStore);

export default createdStore;

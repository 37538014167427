/**
 * enrichStudentStudyPath
 * These functions take in a studentStudyPath and some supporting data and enriches the checkpoints for use in the Study Path
 * as well as deriving some overall SSP status for NextSummative
**/

import { determineAssessmentWindow } from './assessmentFunctions';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { TopicApi } from 'types/backend/topics.types';
import {
  EnrichedStudentStudyPath,
  EnrichedStudentTopicCard,
  EnrichedStudentTopicCardCheckpoints,
  EnrichedStudentTopicCardLearningObjective,
  StudyPathSummaryInfo,
} from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import { StudentTopicCardLearningObjective, StudentTopicCardForStudyPath, StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { StudyPathApi } from 'types/backend/studyPaths.types';
import { CheckpointColumn, ClarityEnum, YesNo } from 'types/backend/shared.types';

function enrichStudentTopicCardCourseLearningObjectives(
  stclos: Array<StudentTopicCardLearningObjective>,
  enrichedCourseLos: Array<EnrichedCourseLearningObjective>,
  enrichedStudentAssessments: Array<EnrichedStudentAssessment>,
  topics: Array<TopicApi>
): {
  enrichedStclos: Array<EnrichedStudentTopicCardLearningObjective>
  metadata: {
    correctPrepIds: Set<number>
    muddyQuestionIds: Set<number>
    recaptureQuestionIds: Set<number>
    totalPrepIds: Set<number>
  }
} {
  let recaptureQuestionIds: Set<number> = new Set();
  let totalPrepIds: Set<number> = new Set();
  let correctPrepIds: Set<number> = new Set();
  let muddyQuestionIds: Set<number> = new Set();
  const enrichedStclos = stclos.reduce((acc: Array<EnrichedStudentTopicCardLearningObjective>, stclo) => {
    const stcloaqs = stclo.assessmentQuestions;
    recaptureQuestionIds = stcloaqs.reduce((count, stcloaq) => {
      const currentAssessment = enrichedStudentAssessments.find(a => a.id === stcloaq.assessment.id);
      if (!currentAssessment) {
        console.error(`enrichedStudentAssessment not found for ${stcloaq.assessment.id}`);
        return recaptureQuestionIds;
      }
      const { dueDate, enrollmentAssessmentDueDate, lateDate } = currentAssessment;
      const currentWindow = determineAssessmentWindow(dueDate, lateDate, enrollmentAssessmentDueDate);
      const saqAndPointsAvailableToRecap = stcloaq.studentAssessmentQuestion && stcloaq.studentAssessmentQuestion.pointsAvailableToRecap;
      if (saqAndPointsAvailableToRecap && (
        (currentWindow === FirstAttemptedEnum.BeforeDue && currentAssessment.allQuestionsAnswered) ||
        (currentWindow === FirstAttemptedEnum.BeforeLate)
      )) {
        recaptureQuestionIds.add(stcloaq.id);
      } else {
        // TODO: Reset to 0 if after late
      }
      return recaptureQuestionIds;
    }, recaptureQuestionIds);

    ({ correctPreps: correctPrepIds, totalPreps: totalPrepIds, muddyCount: muddyQuestionIds } = stcloaqs.reduce((count, q) => {
      if (q.assessment.assessType === AssessTypeEnum.Prep && q.studentAssessmentQuestion && q.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes) {
        correctPrepIds.add(q.id);
        totalPrepIds.add(q.id);
      } else if (q.assessment.assessType === AssessTypeEnum.Prep) {
        totalPrepIds.add(q.id);
      }

      if (q.studentAssessmentQuestion && q.studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt?.clarity === ClarityEnum.Muddy) {
        muddyQuestionIds.add(q.id);
      }
      return { correctPreps: correctPrepIds, totalPreps: totalPrepIds, muddyCount: muddyQuestionIds };
    }, { correctPreps: correctPrepIds, totalPreps: totalPrepIds, muddyCount: muddyQuestionIds }));

    const courseLoData = enrichedCourseLos.find((clo: { id: number }) => clo.id === stclo.id);
    if (!courseLoData) {
      console.error(`enrichedCourseLo data not found for stclo id ${stclo.id}`);
      return acc;
    }
    const { title, _derived: { loNumber, courseWideSort }, stringId, topicId } = courseLoData;
    const topic = topics.find(({ id }) => id === topicId) as TopicApi;
    const enrichedStclo: EnrichedStudentTopicCardLearningObjective = {
      title,
      id: stclo.id,
      loNumber,
      courseWideSort,
      assessmentQuestions: stclo.assessmentQuestions,
      stringId,
      topicName: topic.name,
    };
    acc.push(enrichedStclo);
    return acc;
  }, []);
  return {
    enrichedStclos,
    metadata: {
      correctPrepIds,
      muddyQuestionIds,
      recaptureQuestionIds,
      totalPrepIds,
    },
  };
}

function determineAllAssessmentsCompleted(assessments: Array<EnrichedStudentAssessment>, assessmentIds: Array<string>) {
  // determines if all related checkpoint1Assessments (published and preclass or HW) have been completed or are past due
  return !assessments.some(a =>
    assessmentIds.includes(a.id) &&
    a.published === YesNo.Yes &&
    [AssessTypeEnum.Preclass, AssessTypeEnum.Homework].includes(a.assessType) &&
    a.isBeforeDue &&
    !a.allQuestionsAnswered
  );
}

//this temp function assumes that the `enrichedTopicCard.classSessionIds` Array is sorted in order of classSessions
// and it does not properly sort topics within a classSession (which is more complicated)
// but it is vast improvement over what is in prod today
export function sortTopicCards(enrichedTopicCards: Array<StudentTopicCardForStudyPath>, classSessions: Array<ClassSessionApi>) {
  const sortedCards = enrichedTopicCards.sort((a, b) => {
    const sessionIndexA = classSessions.findIndex(cs => cs.id === a.classSessionIds[0]);
    const sessionIndexB = classSessions.findIndex(cs => cs.id === b.classSessionIds[0]);
    return sessionIndexA - sessionIndexB;
  });
  return sortedCards;
}

function getEnrichedCheckpointsAndSummary(
  studentStudyPath: Required<StudentStudyPathApi>,
  studyPath: Required<StudyPathApi>,
  courseTopics: Array<TopicApi>,
  classSessions: Array<ClassSessionApi>,
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>,
  assessments: Array<EnrichedStudentAssessment>
) {
  const summaryInfo: StudyPathSummaryInfo = {
    checkpointZeroHasTopics: false,
    checkpointOneHasTopics: false,
    checkpointTwoHasTopics: false,
    checkpointThreeHasTopics: false,
    allAssessmentsCompleted: false,
    availableLOs: 0,
    totalLOs: 0,
    questionsForRecapture: 0,
    totalTopics: 0,
    reviewedTopics: 0,
    testMeTopics: 0,
    totalPrepQuestions: 0,
    correctPrepQuestions: 0,
    muddyCount: 0,
  };
  let recaptureQuestionIds: Set<number> = new Set();
  let totalPrepIds: Set<number> = new Set();
  let correctPrepIds: Set<number> = new Set();
  let muddyQuestionIds: Set<number> = new Set();
  const { studentTopicCardCheckpoints } = studentStudyPath;
  const { assessmentIds } = studyPath;
  summaryInfo.allAssessmentsCompleted = determineAllAssessmentsCompleted(assessments, assessmentIds);

  // TODO: this block does tooooo much
  const enrichedCheckpoints = Object.keys(studentTopicCardCheckpoints).reduce((acc: EnrichedStudentTopicCardCheckpoints, chkpnt: string) => {

    const topicCards: Array<StudentTopicCardForStudyPath> = studentTopicCardCheckpoints[chkpnt as CheckpointColumn];

    const enrichedTopicCards: Array<EnrichedStudentTopicCard> = topicCards.map((studentSPTC) => {
      summaryInfo.totalTopics += 1;
      switch (chkpnt) {
        case CheckpointColumn.Review:
          summaryInfo.checkpointOneHasTopics = true;
          break;
        case CheckpointColumn.Prep:
          summaryInfo.reviewedTopics += 1;
          summaryInfo.checkpointTwoHasTopics = true;
          break;
        case CheckpointColumn.Test:
          summaryInfo.reviewedTopics += 1;
          summaryInfo.testMeTopics += 1;
          summaryInfo.checkpointThreeHasTopics = true;
          break;
        default:
          summaryInfo.checkpointZeroHasTopics = true;
      }
      const courseTopic = courseTopics.find(ct => !!ct && ct.id === studentSPTC.topicId) as TopicApi;
      const { enrichedStclos, metadata } = enrichStudentTopicCardCourseLearningObjectives(studentSPTC.learningObjectives, courseLearningObjectives, assessments, courseTopics);
      correctPrepIds = new Set([...correctPrepIds, ...metadata.correctPrepIds]);
      muddyQuestionIds = new Set([...muddyQuestionIds, ...metadata.muddyQuestionIds]);
      recaptureQuestionIds = new Set([...recaptureQuestionIds, ...metadata.recaptureQuestionIds]);
      totalPrepIds = new Set([...totalPrepIds, ...metadata.totalPrepIds]);
      summaryInfo.totalLOs += enrichedStclos.length;
      if (chkpnt !== CheckpointColumn.Hidden) {
        summaryInfo.availableLOs += enrichedStclos.length;
      }
      return {
        ...studentSPTC,
        topic: courseTopic,
        learningObjectives: enrichedStclos,
      };
    });

    const sortedEnrichedTopicCards = sortTopicCards(enrichedTopicCards, classSessions);

    return {
      ...acc,
      [chkpnt]: sortedEnrichedTopicCards,
    };
  }, {
    [CheckpointColumn.Hidden]: [],
    [CheckpointColumn.Review]: [],
    [CheckpointColumn.Prep]: [],
    [CheckpointColumn.Test]: [],
  });

  summaryInfo.questionsForRecapture = recaptureQuestionIds.size;
  summaryInfo.totalPrepQuestions = totalPrepIds.size;
  summaryInfo.correctPrepQuestions = correctPrepIds.size;
  summaryInfo.muddyCount = muddyQuestionIds.size;

  return {
    enrichedCheckpoints,
    summaryInfo,
  };
}

export default function enrichStudentStudyPath(
  studentStudyPath: Required<StudentStudyPathApi>,
  studyPath: Required<StudyPathApi>,
  courseTopics: Array<TopicApi>,
  classSessions: Array<ClassSessionApi>,
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>,
  assessments: Array<EnrichedStudentAssessment>
): EnrichedStudentStudyPath | null {

  if (Object.entries(studentStudyPath).length === 0) {
    console.warn('studentStudyPath is empty', studentStudyPath);
    return null;
  } else {
    // this selector will break if studentTopicCardCheckpoints isn't present so I added this condition
    // if the study path object exists but hasn't been hydrated with the checkpoints
    if (!studentStudyPath.studentTopicCardCheckpoints) {
      console.warn('retrieveEnrichedActiveStudyPath called without studentTopicCardCheckpoints', studentStudyPath);
      return null;
    }
  }

  const { enrichedCheckpoints, summaryInfo } = getEnrichedCheckpointsAndSummary(
    studentStudyPath,
    studyPath,
    courseTopics,
    classSessions,
    courseLearningObjectives,
    assessments
  );

  return {
    studentTopicCardCheckpoints: enrichedCheckpoints,
    id: studentStudyPath.id,
    studyPathId: studentStudyPath.studyPathId,
    enrollmentId: studentStudyPath.enrollmentId,
    summativeAssessmentId: studentStudyPath.summativeAssessmentId,
    createdAt: studentStudyPath.createdAt,
    updatedAt: studentStudyPath.updatedAt,
    summaryInfo,
  };

}

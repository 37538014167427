import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FaChevronLeft } from 'react-icons/fa';

import apiNext from 'api-next';
import createStudentAssessment from '../shared/createStudentAssessment';
import sharedStrings from 'sharedStrings';
import getVatHashesFromSaqa, { VatHashes } from 'utils/getVatHashesFromSaqa';
import { breakdownCourseLosByTopic } from 'utils/courseLearningObjectiveFunctions';
import logExecutionTime from 'utils/logExecutionTime';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import {
  determineAssessmentWindow,
  determineMultipleAttemptPolicy,
  handleFinishInstructor,
  handleStudentAttemptResponse,
  totalFromPointsHash,
  validateInstructorVatAttempt,
} from 'utils/assessmentFunctions';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';
import { useAppSelector } from 'store';

import ReadinessExperienceNavItem from './ReadinessExperienceNavItem';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import Instructions from '../components/Instructions';
import IntroOutro from '../components/IntroOutro';
import OutroContent from '../components/OutroContent';
import AssessmentTakerHeader from '../components/AssessmentTakerHeader';
import LearnosityContainer, { L8yContainerValidated, L8yEventPayload, L8yExitPayload } from 'shared-components/LearnosityContainer/LearnosityContainer';
import { assessmentDataProp } from '../shared/AssessmentTakerPropTypes';
import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { L8yContainerEvents, QuestionStatusHash } from 'shared-components/LearnosityContainer/LearnosityContainer.types';

import { ClarityHash, MappedAssessmentQuestion } from 'types/common.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTakerController/AssessmentTakerController.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { SpatHashes } from '../../StudyPathController/sharedStudyPathFunctions';
import { AssessmentLocation } from 'types/backend/shared.types';
import { PageStates, Steps } from '../AssessmentTakerController.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import './ReadinessExperience.scss';

function ReadinessExperience({
  assessmentData,
  handleDone,
  spatL8ySessionId,
  location,
  refreshAssessmentQuestions,
  questions,
  initSpatHashes,
  targetL8yId,
  initStudentAssessmentId,
}: {
  assessmentData: AssessmentWithEnrollment
  handleDone?: () => void
  initSpatHashes?: SpatHashes
  initStudentAssessmentId?: number
  location: AssessmentLocation
  questions: Array<AssessmentControllerQuestion>
  refreshAssessmentQuestions?: (assessmentId: string) => Promise<Array<AssessmentControllerQuestion>>
  spatL8ySessionId?: string
  targetL8yId?: string
}) {
  const isSPAT = location !== AssessmentLocation.REX;
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const user = useAppSelector((store) => store.user);
  const enrollmentData = useAppSelector((store) => store.active.enrollment);
  const isInstructor = useAppSelector((store) => !!store.state.instructorStudentViewCourseId);
  const assessmentQuestionMaps: Array<MappedAssessmentQuestion> = useAppSelector(retrieveActiveAssessmentQuestionMaps);
  const enrichedStudentAssessments = useAppSelector(retrieveEnrichedStudentAssessments);
  const studentAssessments = useAppSelector((store) => store.active.studentAssessments);
  const { id: existingStudentAssessmentId, l8ySessionId: existingL8ySessionId } = studentAssessments.find((sa) => sa.assessmentId === assessmentData.id) || {};

  const questionL8yIdArray = questions.map(({ l8yId }) => l8yId);
  const [currentStep, setCurrentStep] = useState(Steps.Intro);
  const [currentStage, setCurrentStage] = useState(AssessmentTakerQuestionStage.INIT);
  const [correctQuestionCount, updateCorrectQuestionCount] = useState(0);
  const [earnedPoints, updateEarnedPoints] = useState(0);
  const [totalLatePointsDeducted, setTotalLatePointsDeducted] = useState(-1);
  const initL8ySessionId = spatL8ySessionId || existingL8ySessionId || '';
  const [l8ySessionId, setL8ySessionId] = useState(initL8ySessionId);
  const [loHeaderExpanded, setLoHeaderExpanded] = useState(!isSPAT);

  const allClosFromQuestions = questions.map(({ loData }) => loData).flat();
  const initTargetL8yId = targetL8yId || questionL8yIdArray[0];
  // show all LOs on initial load, group this state together since it should always change at the same time
  const [{ activeL8yId, visibleLoIds }, setActiveItemData] = useState({
    visibleLoIds: [] as Array<number>,
    activeL8yId: initTargetL8yId,
  });

  const commonHashes = initSpatHashes || {
    clarityHash: {},
    correctHash: {},
    latePointsDeductedHash: {},
    pointsHash: {},
    recapHash: {},
  };

  const [vatHashes, updateVatHashes] = useReducer((prev: VatHashes, next: Partial<VatHashes>) => {
    return { ...prev, ...next };
  }, {
    ...commonHashes,
    attemptsHash: {},
    vatFrozenHash: {},
  } as VatHashes);
  const [studentAssessmentId, setStudentAssessmentId] = useState(initStudentAssessmentId || existingStudentAssessmentId || -1);
  const {
    dueDate,
    enrollmentAssessmentDueDate,
    id: assessmentId,
    mergedOpenDate,
    lateDate,
  } = assessmentData;

  const { gradingPolicy, freeAttempts, pointPenalty } = assessmentData;
  const attemptPolicy = determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);

  let assessmentStatus = undefined;
  const currentWindow = determineAssessmentWindow(dueDate, lateDate, enrollmentAssessmentDueDate);
  const isAfterLate = currentWindow === FirstAttemptedEnum.AfterLate;
  const currentAssessment = enrichedStudentAssessments.find(assign => assign.id === assessmentData.id);
  if (currentAssessment) {
    assessmentStatus = currentAssessment.assessmentStatus;
  }

  if (assessmentStatus === undefined) {
    if (currentWindow === FirstAttemptedEnum.BeforeDue) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
    } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
      assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
    } else { // after late
      assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
    }
  }

  const assessmentQuestionMapsForAssessment = assessmentQuestionMaps.filter((aqm: AssessmentQuestionApi) => aqm.assessmentId === assessmentId);
  const totalQuestions = questions.length;
  const filteredAssessmentQuestionMap = assessmentQuestionMapsForAssessment.filter(({ _derived: { questionData: { l8yId } } }) => questionL8yIdArray.includes(l8yId));
  const totalPoints = filteredAssessmentQuestionMap.map(({ points }) => points).reduce((a, b) => a + b, 0);
  const handleStep = (step: Steps, force?: boolean) => {
    setCurrentStep(force ? step : PageStates[step].next);
  };

  const handleReview = () => {
    if (isInstructor) {
      window.location.reload();
    }
    handleStep(Steps.Intro);
  };

  const handleStartSession = async () => {
    if (!!refreshAssessmentQuestions) {
      const startTime = performance.now();
      // get the very latest assessmentQuestions in case there has been a last minute change
      const refreshedQuestions = await refreshAssessmentQuestions(assessmentData.id);
      if (!refreshedQuestions.length) {
        triggerConfirmationPrompt({
          title: 'Error Starting Assignment',
          message: 'There are no questions in this assignment. Please check back later.',
          onConfirm: () => {},
        });
        return;
      }
      // Get role from enrollment
      if (enrollmentData.roleId === RoleEnum.Student) {
        const studentAssessmentData = await createStudentAssessment({ assessmentData, enrollmentData }) as Required<StudentAssessmentApi>;
        setStudentAssessmentId(studentAssessmentData.id);
        setL8ySessionId(studentAssessmentData.l8ySessionId);
        const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestionsByStudentAssessmentId(studentAssessmentData.id) as Array<StudentAssessmentQuestionApiWithSaqas>;
        // get initial state of attempt-related data based on latestStudentAssessmentQuestionAttempt
        const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, studentAssessmentQuestions);
        updateVatHashes(saqaHashes);
      } else if (isInstructor) {
        setL8ySessionId(assessmentData.id);
        const saqaHashes = getVatHashesFromSaqa(refreshedQuestions, [], true);
        updateVatHashes(saqaHashes);
      }
      logExecutionTime(startTime, 'ReadinessExperience handleStartSession');
    }
    handleStep(Steps.Intro);
  };

  const handleL8yContainerEvents = async ({ type, data }: L8yEventPayload) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        const { activeL8yRef } = data;
        if (!!activeL8yRef) {
          const targetQuestion = questions.find((q) => q.l8yId === activeL8yRef) as AssessmentControllerQuestion;
          const { loData = [] } = targetQuestion;
          setActiveItemData({ activeL8yId: activeL8yRef, visibleLoIds: loData.map(({ id }) => id) });
        }
        setCurrentStage(AssessmentTakerQuestionStage.INIT);
        break;
      }
      case L8yContainerEvents.QUESTION_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.ANSWER_CHANGED);
        break;
      }
      case L8yContainerEvents.HANDLE_CLARITY: {
        updateVatHashes({ clarityHash: data.clarityHash as ClarityHash });
        break;
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        const { loData } = questions.find(({ l8yId }) => l8yId === initTargetL8yId) as AssessmentControllerQuestion;
        // only set activeItemData on initial load to load the correct topic/LO in header for initial item
        if (!!loData?.length && !visibleLoIds.length) {
          setActiveItemData((prev) => ({ ...prev, visibleLoIds: loData.map(({ id }) => id) }));
        }
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const handleValidated = async (data: L8yContainerValidated) => {
    const { assessmentQuestionId, attemptData, score, isCorrect, clarity, rawMaxScore } = data;
    if (isInstructor) {
      return validateInstructorVatAttempt(assessmentData, data, studentAssessmentId);
    }
    const studentAttemptResponse = await apiNext.createStudentAttempt({
      assessmentQuestionId,
      attemptData,
      clarity,
      isCorrect,
      location,
      rawMaxScore,
      rawPointsEarned: score,
      studentAssessmentId,
    });
    return handleStudentAttemptResponse({
      assessmentQuestionId,
      location,
      studentAssessmentId,
      studentAttemptResponse,
      triggerConfirmationPrompt,
    });
  };

  const onFinished = async ({ correctQuestionsCount, ...updatedVatHashes }: L8yExitPayload) => {
    let newTotalPointsEarned = -1;
    let newCorrectQuestionCount = -1;
    if (isInstructor) {
      ({ totalPointsEarned: newTotalPointsEarned, correctQuestionCount: newCorrectQuestionCount } = handleFinishInstructor(updatedVatHashes));
    } else {
      const updatedStudentAssessments = await apiNext.getStudentAssessment(assessmentData.id, enrollmentData.id);
      const saData = updatedStudentAssessments.find((sa) => sa.assessmentId === assessmentData.id) as StudentAssessmentApi;
      ({ totalPointsEarned: newTotalPointsEarned } = saData);
      newCorrectQuestionCount = correctQuestionsCount;
    }
    if (newTotalPointsEarned !== -1) {
      updateEarnedPoints(newTotalPointsEarned);
    }
    const { latePointsDeductedHash: latePointsDeducted } = updatedVatHashes;
    const newTotalLatePointsDeducted = totalFromPointsHash(latePointsDeducted);
    if (newTotalLatePointsDeducted !== -1) {
      setTotalLatePointsDeducted(newTotalLatePointsDeducted);
    }
    updateVatHashes(updatedVatHashes);
    updateCorrectQuestionCount(newCorrectQuestionCount);
    if (isSPAT && !!handleDone) {
      handleDone();
    } else {
      handleStep(Steps.Outro, true);
    }
  };

  const renderRex = (step: Steps) => {
    const loDisplay = breakdownCourseLosByTopic(allClosFromQuestions);
    switch (step) {
      case Steps.Intro: {
        const openString = DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.WeekdayDateAtTime);
        return (
          <IntroOutro>
            <Instructions assessmentData={assessmentData} attemptPolicy={attemptPolicy} />
            <div className="assessment__action">
              {DateTime.fromISO(mergedOpenDate) < DateTime.local() || isInstructor
                ? <BetterButton className="gray-button" text="Start" onClick={handleStartSession} />
                : `This assessment will open on ${openString}.`
              }
            </div>
          </IntroOutro>
        );
      }
      case Steps.Assessment: {
        if (studentAssessmentId === -1 && !isInstructor) {
          console.error('Cannot render Assessment without valid studentAssessmentId', studentAssessmentId);
          return null;
        }
        const { attemptsInAt, name: l8yName } = assessmentData;
        const items = questions.map(({ gradingType, l8yId, type }) => ({ gradingType, l8yId, type }));

        const handleNavClick = (question: AssessmentControllerQuestion, handleItemNav: (l8yId: string) => void) => {
          const { l8yId, learningObjectiveIds = [] } = question;
          setActiveItemData({ activeL8yId: l8yId, visibleLoIds: learningObjectiveIds });
          handleItemNav(l8yId);
        };
        const losToDisplay = loDisplay.some(({ topicClos }) => topicClos.some(({ id }) => visibleLoIds.includes(id)));
        return (
          <div className="assessment-wrap" data-assessmentstage={currentStage}>
            <div className="assessment-taker__header" data-collapsed={!loHeaderExpanded} data-showheader={losToDisplay}>
              <button className="toggle-lo-button" onClick={() => setLoHeaderExpanded(!loHeaderExpanded)}>
                {loHeaderExpanded ? <>Hide <abbr title="Learning Objective">LO</abbr>s</> : <>Show <abbr title="Learning Objective">LO</abbr>s</>}
              </button>
              {loDisplay.map(({ topicClos, topic, topicId }) => loHeaderExpanded ? (
                <div className="topic-with-los" key={`${topicId}_${topic.name}`} data-showtopic={topicClos.some((tclo) => visibleLoIds.includes(tclo.id))}>
                  <div className="assessment-taker__header__topic-title">
                    <h1>{topic.name}</h1>
                  </div>
                  <div>
                    {topicClos.map(({ id: loId, title, loNumber }) => (
                      <div className="assessment-taker__header__lo-item" data-showlo={!visibleLoIds.length || visibleLoIds.includes(loId)} key={`${topicId}_${loId}`}>
                        <strong className="assessment-taker__header__lo-number">
                          {loNumber.replace('LO', 'Learning Objective')}
                        </strong>:&nbsp;
                        <span>{title}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <button className="topic-with-los" onClick={() => setLoHeaderExpanded(true)} key={`${topicId}_${topic.name}`} data-showtopic={topicClos.some((tclo) => visibleLoIds.includes(tclo.id))}>
                  <div className="collapsed-topic-title">{topic.name}</div>
                  <div className="collapsed-topic-los">
                    {topicClos.map(({ id: loId, loNumber }) => (
                      <span className="assessment-taker__header__lo-item collapsed-lo-number" data-showlo={!visibleLoIds.length || visibleLoIds.includes(loId)} key={`${loId}_${loNumber}`}>
                        {loNumber}
                      </span>
                    ))}
                  </div>
                </button>
              ))}
            </div>
            <LearnosityContainer
              activityId={studentAssessmentId.toString()}
              assessmentMode={AssessmentModeEnum.SubmitPractice}
              assessmentType={AssessTypeEnum.Readiness}
              attemptLimit={attemptsInAt}
              attemptPolicy={attemptPolicy}
              handleEvents={handleL8yContainerEvents}
              handleFinished={onFinished}
              handleValidated={handleValidated}
              initAttemptsHash={vatHashes.attemptsHash}
              initClarityHash={vatHashes.clarityHash}
              initCorrectHash={vatHashes.correctHash}
              initLatePointsDeductedHash={vatHashes.latePointsDeductedHash}
              initPointsHash={vatHashes.pointsHash}
              initRecapHash={vatHashes.recapHash}
              initVatFrozenHash={vatHashes.vatFrozenHash}
              inReviewMode={false}
              isInstructor={isInstructor}
              isAfterLate={isAfterLate}
              items={items}
              l8ySessionId={l8ySessionId}
              l8yBoxClassName="col-xs-12 col-sm-9"
              location={location}
              name={l8yName}
              questionData={questions}
              renderingType={RenderingTypeEnum.Assess}
              renderItemNav={(activeL8yRef: string, handleItemNav: (l8yId: string) => void, questionStatusHash: QuestionStatusHash) => (
                <div className="col-xs-12 col-sm-3 nav-menu__wrap">
                  <nav aria-label="Assessment Table of Contents" className="readiness-nav-menu">
                    <div className="nav-item__header"></div>
                    {!isSPAT && (
                      <button className="rex-nav-item__about-link" onClick={() => setCurrentStep(Steps.Intro)}>
                        <FaChevronLeft />
                        About this Assignment
                      </button>
                    )}
                    <ul className="rex-nav-list">
                      {questions.map((question) => (
                        <ReadinessExperienceNavItem
                          assessmentQuestion={question}
                          handleClick={() => handleNavClick(question, handleItemNav)}
                          isActive={activeL8yRef === question.l8yId}
                          isSPAT={isSPAT}
                          questionStatus={questionStatusHash[question.l8yId]}
                          key={question.id}
                        />
                      ))}
                    </ul>
                    <div className="nav-item__footer"></div>
                  </nav>
                </div>
              )}
              studentAssessmentId={studentAssessmentId}
              targetL8yId={activeL8yId}
              userId={user.id}
            />
          </div>
        );
      }
      case Steps.Outro: {
        const { clarityHash } = vatHashes;
        return (
          <OutroContent
            assessmentControllerQuestions={questions}
            assessType={AssessTypeEnum.Readiness}
            clarityHash={clarityHash}
            correctQuestionCount={correctQuestionCount}
            courseId={enrollmentData.courseId}
            earnedPoints={earnedPoints}
            gradingPolicy={assessmentData.gradingPolicy}
            handleReviewAssessment={handleReview}
            isInstructor={isInstructor}
            totalLatePointsDeducted={totalLatePointsDeducted}
            totalPoints={totalPoints}
            totalQuestions={totalQuestions}
          />
        );
      }
      default: return null;
    }
  };

  if (isSPAT) {
    return (
      <div className="study-path__readiness-experience">
        {renderRex(Steps.Assessment)}
      </div>
    );
  }

  return (
    <div className="assessment-taker-root assessment-taker-readiness-experience">
      <AssessmentTakerHeader
        assessmentData={assessmentData}
        assessmentStatus={assessmentStatus}
        totalPoints={totalPoints}
      />
      {isInstructor && (
        <div className="assessment-taker-root__instructor-banner">
          {sharedStrings.INSTRUCTOR_PREVIEW_ASSESSMENT}
          <button onClick={() => window.location.reload()}>
            {sharedStrings.INSTRUCTOR_PREVIEW_RESTART_ASSESSMENT}
          </button>
        </div>
      )}
      <div className="assessment-taker-root__wrap">
        { renderRex(currentStep) }
      </div>
    </div>
  );
}

ReadinessExperience.propTypes = {
  assessmentData: assessmentDataProp,
  handleDone: PropTypes.func,
  location: PropTypes.oneOf(Object.values(AssessmentLocation)).isRequired,
  questions: PropTypes.array.isRequired,
  refreshAssessmentQuestions: PropTypes.func,
  targetL8yId: PropTypes.string,
  initStudentAssessmentId: PropTypes.number,
};

export default ReadinessExperience;


import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FaUserFriends } from 'react-icons/fa';

import { formatStringsWithAnd } from 'utils/commonFormattingFunctions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { CourseApi } from 'types/backend/courses.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentCoursePath } from 'types/student.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { PositionEnum } from 'types/common.types';
import { UserApi } from 'types/backend/users.types';
import './CourseList.scss';

interface CourseListProps {
  courses: Array<CourseApi>
  enrollmentRole: RoleEnum
  linkPrefix: string
  showRoleHeader: boolean
  coinstructorsFromEnrolledCourses?: Array<UserApi>
}

function CourseList({ courses, enrollmentRole, linkPrefix, showRoleHeader, coinstructorsFromEnrolledCourses = [] }: CourseListProps) {

  function reduceString(string: string, maxLength: number, separator = ' ') {
    if (string.length <= maxLength) {
      return string;
    }
    return string.substr(0, string.lastIndexOf(separator, maxLength));
  }

  function renderCourseCard(course: CourseApi) {
    const { name: courseName, courseNumber, id: courseId } = course;
    const isCourseInstructor = enrollmentRole === RoleEnum.Instructor;
    const coinstructors = coinstructorsFromEnrolledCourses.filter((co) => co.courseId === courseId);
    const coursePath = isCourseInstructor ? InstructorCoursePath.DailyPlanner : StudentCoursePath.Home;
    return (
      <div key={courseId} data-courseid={courseId} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="course-list__course" data-coursename={courseName}>
          <div className="course-list__image" style={{ backgroundImage: 'url("/course-thumbnail-placeholder.jpg")' }}/>
          <div className="course-list__content">
            <h3 className="course-list__title">{reduceString(courseName, 64)}</h3>
            <div className="course-list__details">
              <div className="course-list__number">
                <Link
                  aria-label={`Course Number: ${courseNumber}`}
                  title={`Link to ${courseName}`}
                  to={`${linkPrefix}/course/${courseId}/${coursePath}`}
                >
                  {courseNumber}
                </Link>
              </div>
              {!!coinstructors.length && (
                <div className="course-list__coinst-icon">
                  <BetterTooltip content={`This course is co-taught with ${formatStringsWithAnd(coinstructors.map(co => `${co.firstName} ${co.lastName}`))}`} position={PositionEnum.Bottom}>
                    <FaUserFriends/>
                  </BetterTooltip>
                </div>
              )}
            </div>
          </div>
          <div className="course-list__overlay">
            {isCourseInstructor ? (
              <Link
                to={`${linkPrefix}/course/${courseId}/${coursePath}`}
                className="course-list__button-link"
              >
                <div className="course-list__button">
                  View/Edit Course
                </div>
              </Link>
            ) : (
              <Link
                to={`student/course/${courseId}/${coursePath}`}
                data-courseid={courseId}
                className="course-list__button-link"
              >
                <div className="course-list__button">
                  View Course
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }

  const renderCourseListSection = (courseArray: Array<CourseApi>, header: string) => {
    if (!courseArray.length) {
      return null;
    }
    return (
      <div className="course-list__section">
        <div className="course-list__top-wrapper">
          <h2 className="h5 course-list__header-title">{header}</h2>
        </div>
        <div className="course-list__courses row">
          {courseArray.map(renderCourseCard)}
        </div>
      </div>
    );
  };

  const today = DateTime.now();
  const { current, future, past } = courses.reduce((acc, course: CourseApi) => {
    const startDate = DateTime.fromISO(course.startDate);
    const endDate = DateTime.fromISO(course.endDate);

    if (startDate <= today && today <= endDate) {
      return {
        ...acc,
        current: [
          ...acc.current,
          course,
        ],
      };
    } else if (startDate > today) {
      return {
        ...acc,
        future: [
          ...acc.future,
          course,
        ],
      };
    } else if (endDate < today) {
      return {
        ...acc,
        past: [
          ...acc.past,
          course,
        ],
      };
    }
    return acc;
  }, { current: [], future: [], past: [] } as { [key: string]: Array<CourseApi> }) || {};

  return (
    <div className="course-list">
      <div className="course-list__wrapper">
        {showRoleHeader && (
          <h2 className="h3 course-list__header-title">
            {enrollmentRole === RoleEnum.Instructor ? 'Courses I teach' : "Courses I'm enrolled in"}
          </h2>
        )}
        {renderCourseListSection(current, 'Current Courses')}
        {renderCourseListSection(future, 'Future Courses')}
        {renderCourseListSection(past, 'Past Courses')}
      </div>
    </div>
  );
}

CourseList.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    courseNumber: PropTypes.string,
    courseDays: PropTypes.arrayOf(PropTypes.number),
    creatorUser: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    users: PropTypes.array,
  })).isRequired,
  linkPrefix: PropTypes.oneOf(['/student', '/instructor']),
  enrollmentRole: PropTypes.number.isRequired,
  showRoleHeader: PropTypes.bool,
};

CourseList.defaultProps = {
  linkPrefix: '/instructor',
};

export default CourseList;

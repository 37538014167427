/**
 * Intentionally slim wrapper enabling us to re-use the AssessmentTakerReadinessExperience component
 */
import React from 'react';
import { useSelector } from 'react-redux';

import getAssessmentControllerQuestions from 'utils/getAssessmentControllerQuestions';
import { getStudyPathHashesFromSaqa, SpatHashes } from '../../sharedStudyPathFunctions';
import retrieveAssessmentsWithEnrollment from 'store/selectors/retrieveAssessmentsWithEnrollment';
import retrieveActiveCourseLearningObjectives from 'store/selectors/retrieveActiveCourseLearningObjectives';
import AssessmentTakerReadinessExperience from 'student/controllers/Course/AssessmentTakerController/ReadinessExperience/ReadinessExperience';
import { AssessmentLocation } from 'types/backend/shared.types';
import { StudentTopicCardReadinessAssessment } from 'types/backend/studentStudyPaths.types';
import { Store } from 'types/store.types';

function StudyPathReadinessContainer({
  assessmentId,
  handleDone,
  readinessAssessments,
  l8ySessionId,
  targetL8yId,
}: {
  assessmentId: string
  handleDone: () => void
  l8ySessionId: string
  readinessAssessments: Array<StudentTopicCardReadinessAssessment>
  targetL8yId: string
}) {
  const enrichedAssessments = useSelector(retrieveAssessmentsWithEnrollment);
  const activeAQMs = useSelector((store: Store) => store.active.assessmentQuestionMaps);
  const courseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const allQuestions = useSelector((store: Store) => [...store.active.templateQuestions, ...store.active.userQuestions]);
  const questions = getAssessmentControllerQuestions({
    assessmentId,
    assessmentQuestionMaps: activeAQMs,
    courseLearningObjectives,
    questions: allQuestions,
  });
  const currentAssessmentData = enrichedAssessments.find((en) => en.id === assessmentId);
  if (!currentAssessmentData || !readinessAssessments) {
    console.warn('currentAssessmentData or readinessAssessments missing', currentAssessmentData, readinessAssessments);
    return null;
  }
  const { assessmentQuestions: readinessAssessmentQuestions } = readinessAssessments.find((ra) => ra.id === assessmentId) as StudentTopicCardReadinessAssessment;
  const initSpatHashes: SpatHashes = getStudyPathHashesFromSaqa(readinessAssessmentQuestions);
  const [ { studentAssessmentQuestion: firstSaq } ] = readinessAssessmentQuestions;
  const { studentAssessmentId } = firstSaq || {};
  return (
    <AssessmentTakerReadinessExperience
      assessmentData={currentAssessmentData}
      handleDone={handleDone}
      spatL8ySessionId={l8ySessionId}
      location={AssessmentLocation.SP}
      questions={questions}
      initSpatHashes={initSpatHashes}
      targetL8yId={targetL8yId}
      initStudentAssessmentId={studentAssessmentId}
    />
  );
}

export default StudyPathReadinessContainer;

import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import apiNext from 'api-next';
import isStudentRole from 'utils/isStudentRole';
import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import logout from 'utils/logout';
import { useAppDispatch } from 'store';
import { saveLogMessage } from 'utils/saveLogMessage';
import passiveSlice from 'store/slices/passive';
import CourseListController from 'student/controllers/CourseList/CourseListController';
import Course from 'student/controllers/Course/Course';
import DowntimeNotification from 'shared-components/ToasterNotification/DowntimeNotification';
import InstructorCourse from 'instructor/controllers/Course/Course';
import StudentGlobalNav from 'shared-components/GlobalNav/GlobalNav';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import { Store } from 'types/store.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { RoleEnum } from 'types/backend/roles.types';
const version = process.env.REACT_APP_VERSION || '0.0.0';

interface StudentAppState {
  modalIsOpen: boolean
}

/**
 * StudentAppContext is for storing state that needs to be available wherever we need it within the student view
 * initially because we need to be able to toggle aria-hidden for the rest of the content while the modal is open
 * but eventually it could be used for other student state
 */
export const StudentAppContext = createContext<{
  studentAppState: StudentAppState
  setStudentAppState: React.Dispatch<React.SetStateAction<StudentAppState>>
}>({
  studentAppState: {
    modalIsOpen: false,
  },
  setStudentAppState: () => {},
});

export default function StudentBase() {
  const { path } = useRouteMatch();
  const instructorStudentViewCourseId = useSelector((store: Store) => store.state.instructorStudentViewCourseId);
  const enrollments = useSelector((store: Store) => store.enrollments);
  const user = useSelector((store: Store) => store.user);
  const dispatch = useAppDispatch();
  const [studentAppState, setStudentAppState] = useState({ modalIsOpen: false });
  const { modalIsOpen } = studentAppState;
  const isStudent = isStudentRole(user);
  useIdleLogout(user, IdleLogoutInstance.Student);

  /* Data Fetching Effect for Student base
   * When login or reauth is sucesssful and we have a user object,
   * Grab course information from the DB
   * it for use throughout the application.
   */
  const loadPassiveData = useCallback(async (userId: string, userEnrollments: Array<EnrollmentApi>) => {
    try {
      console.debug('Loading Passive Data For Student', userId, userEnrollments);
      const instructorCourseIds: Array<string> = [];
      const studentCourseIds: Array<string> = [];
      for (const enrollment of userEnrollments) {
        if (enrollment.roleId === RoleEnum.Instructor) {
          instructorCourseIds.push(enrollment.courseId);
        } else if (enrollment.roleId === RoleEnum.Student) {
          studentCourseIds.push(enrollment.courseId);
        }
      }
      // Student DOES NOT NEED anything but courses and products in passive. Everything else for students lives with course level data in active
      const [instructorCourses, studentCourses, products] = await Promise.all([
        apiNext.getCourses(instructorCourseIds),
        apiNext.getCourses(studentCourseIds),
        apiNext.getProducts(),
      ]);
      dispatch(
        passiveSlice.actions.setPassiveData({
          subjects: [],
          units: [],
          topics: [],
          instructorCourses,
          studentCourses,
          coinstructorsFromEnrolledCourses: [],
          products,
        })
      );
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) &&
          (error?.response?.status === 401 || error?.message.includes('401'))) {
        await logout(dispatch);
      } else {
        await saveLogMessage(`WEB ${version} - ${JSON.stringify(error, null, 2)}`);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }
    // if we have a student role with enrollments, load passive data
    if (isStudent && !!enrollments.length) {
      loadPassiveData(user.id, enrollments);
    }
  }, [
    dispatch,
    enrollments,
    instructorStudentViewCourseId,
    isStudent,
    loadPassiveData,
    user,
  ]);

  return (
    <StudentAppContext.Provider value={{ studentAppState, setStudentAppState }}>
      <div className="student-base" aria-hidden={modalIsOpen}>
        <div className="app_top">
          <StudentGlobalNav isInstructor={!!instructorStudentViewCourseId} userData={user} />
          <DowntimeNotification show={false} />
        </div>
        <div className="app__base">
          <ErrorBoundary>
            <Switch>
              <Route exact path={path}>
                <CourseListController/>
              </Route>
              <Route path={`${path}/course/:id`}><Course /></Route>
              <Route path={`${path}/course/:id`}><InstructorCourse /></Route>
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </StudentAppContext.Provider>
  );
}

/**
 * l8yRenderMethods
 *
 * Originally these methods were composing the feedback rationale purely as HTML strings, because that is
 * what is returned from Learnosity metadata. It has since been converted to compose the HTML in JSX world.
 * My reasoning is that this change makes it easier to maintain and improve upon these rendering methods.
 * The inputs and outputs are still HTML as string, but IMO it is worth doing the conversion to React
 * to maintain consistency and allow for better at a glance understanding of what is happening.
 *
 * About the use of dangerouslySetInnerHTML:
 * It is warranted in this case because we are receiving strings from a trusted source that we control.
 * The 'dangerously' part pertains to Cross Site Scripting injection, which is more of a concern if you were to
 * allow user input to set inner HTML content, but we are not doing that.
 **/

import React, { ReactNode } from 'react';
import { renderToString } from 'react-dom/server';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';
import { L8yQuestionType } from 'types/backend/l8y.types';
import { YesNo } from 'types/backend/shared.types';
import {
  LearnosityGetQuestion,
  LearnosityIncorrect,
  LearnosityResponseLevel,
  LearnosityResponseValue,
} from './LearnosityContainer.types';

export interface FeedbackData<T extends LearnosityResponseValue> {
  distractorRationale?: string
  isValid: boolean
  questionData: LearnosityGetQuestion
  responseLevel: LearnosityResponseLevel | false
  responseValue?: T
}

interface FeedbackResult {
  responseId: string
  isCorrect: YesNo
  outputJSX: ReactNode
}

// Learnosity returns HTML as strings, this function converts it to JSX
const fromHtmlString = (__html: string) => {
  if (!__html || __html === '&nbsp;') {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html }} />;
};

const getIncorrectDistractorList = (incorrect: Array<LearnosityIncorrect>): Array<string> => {
  return incorrect.reduce((acc: Array<string>, inc) => {
    const { value, metadata } = inc;
    if (!!value && !!metadata) {
      acc.push(metadata);
    }
    return acc;
  }, []);
};

const buildDistractorList = (distractorList: Array<string>): ReactNode => {
  return (
    <ul>
      {distractorList.map((distractorStr) => (
        <li key={distractorStr} dangerouslySetInnerHTML={{ __html: distractorStr }} />
      ))}
    </ul>
  );
};

const renderFeedbackMCQ = ({
  questionData,
  responseLevel,
  isValid,
}: FeedbackData<string>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (responseLevel !== false) {
    const { correct, incorrect } = responseLevel;
    if (isValid) {
      const [ { metadata } ] = correct;
      isCorrect = YesNo.Yes;
      outputJSX = fromHtmlString(metadata);
    } else {
      const [ { metadata } ] = incorrect;
      outputJSX = fromHtmlString(metadata);
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackMSQ = ({
  questionData,
  responseLevel,
  isValid,
  distractorRationale = '',
}: FeedbackData<string>): FeedbackResult => {
  // src: https://github.com/Learnosity/learnosity-demos/blob/master/www/assessment/distractor-rationale.php#L270
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = fromHtmlString(distractorRationale);
  } else if (responseLevel !== false) {
    const { correct, incorrect, unattempted } = responseLevel;
    const someCorrect = !!correct.length;
    const someIncorrect = !!incorrect.length;
    const someUnattempted = !!unattempted.length;
    if ((someCorrect && !someIncorrect && someUnattempted)
      || (!someCorrect && !someIncorrect && someUnattempted)) {
      // if not fully correct and no incorrect answers selected, or nothing selected
      outputJSX = 'At least one more option should be selected.';
    } else if (someIncorrect) {
      // if some incorrect answers selected: show specific feedback for those answers
      const distractorList = incorrect.reduce((acc: Array<string>, { metadata }) => {
        if (!!metadata) {
          acc.push(metadata);
        }
        return acc;
      }, []);
      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackClozeDDQ = ({
  questionData,
  responseLevel,
  responseValue = [],
  isValid,
  distractorRationale = '',
}: FeedbackData<Array<string>>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = fromHtmlString(distractorRationale);
  } else {
    const { validation: { valid_response: { value: correctAnswer } } } = questionData;
    const correctAnswerArray = correctAnswer as Array<string>;
    const anyIncorrectlyBlank = correctAnswerArray.some((c, i) => !!c && !responseValue[i]);
    const anyIncorrect = correctAnswerArray.some((c, i) => !!responseValue[i] && responseValue[i] !== c);
    if (responseLevel !== false) {
      // if there's feedback written for incorrect responses: show that
      const { incorrect } = responseLevel;
      const distractorList = getIncorrectDistractorList(incorrect);
      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }
    // if any incorrectly blank and no incorrect responses: show the generic message
    if (!outputJSX && anyIncorrectlyBlank && !anyIncorrect) {
      outputJSX = 'You have not filled in all the blanks.';
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackLabelImageDDQ = ({
  questionData,
  responseLevel,
  responseValue = [],
  isValid,
  distractorRationale = '',
}: FeedbackData<Array<string>>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = fromHtmlString(distractorRationale);
  } else {
    const { validation: { valid_response: { value: correctAnswer } } } = questionData;
    const correctAnswerArray = correctAnswer as Array<string>;
    if (responseLevel !== false) {
      // if there's feedback written for incorrect responses: show that
      const { incorrect } = responseLevel;
      const distractorList = getIncorrectDistractorList(incorrect);
      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }
    const anyIncorrectlyBlank = correctAnswerArray.some((c, i) => !!c && !responseValue[i]);
    const anyIncorrect = correctAnswerArray.some((c, i) => !!responseValue[i] && responseValue[i] !== c);
    // if any incorrectly blank and no incorrect responses: show the generic message
    if (!outputJSX && anyIncorrectlyBlank && !anyIncorrect) {
      outputJSX = 'You have not filled in all the blanks.';
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackHotspotQ = ({
  questionData,
  responseValue = [],
  isValid,
  distractorRationale,
}: FeedbackData<Array<string>>): FeedbackResult => {
  // distractor rationale field for hotspot contains general *incorrect* feedback
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: just show correct banner
    isCorrect = YesNo.Yes;
  } else {
    const { validation: { valid_response } } = questionData;
    const correctAnswerArray = valid_response.value as Array<string>;
    const anyIncorrect = responseValue.some(r => !correctAnswerArray.includes(r));
    if (!anyIncorrect) {
      // if no incorrect responses: show generic message
      outputJSX = 'At least one more area should be selected.';
    } else if (!!distractorRationale) {
      // if general incorrect feedback is written: show that
      outputJSX = fromHtmlString(distractorRationale);
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackSortListQ = ({
  questionData,
  responseValue = [],
  isValid,
  distractorRationale,
}: FeedbackData<Array<number>>): FeedbackResult => {
  // distractor rationale field for sortlist contains general *incorrect* feedback
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: just show correct banner
    isCorrect = YesNo.Yes;
  } else {
    const { validation: { valid_response } } = questionData;
    const correctAnswerArray = valid_response.value as Array<number>;
    // response values are numeric indexes, so we need to account for 0
    const anyIncorrect = correctAnswerArray.some((c, i) => (!!responseValue[i] || responseValue[i] === 0) && responseValue[i] !== c);
    if (!anyIncorrect) {
      // if no incorrect responses: show generic message
      outputJSX = 'A complete answer includes all entries in the Source list.';
    } else if (!!distractorRationale) {
      // if general incorrect feedback is written: show that
      outputJSX = fromHtmlString(distractorRationale);
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackOrderListQ = ({
  questionData,
  isValid,
  distractorRationale = '',
}: FeedbackData<string>): FeedbackResult => {
  // distractor rationale field for orderlist contains general *incorrect* feedback
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: just show correct banner
    isCorrect = YesNo.Yes;
  } else if (!!distractorRationale) {
    // if general incorrect feedback is written: show that
    outputJSX = fromHtmlString(distractorRationale);
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackClassificationQ = ({
  questionData,
  responseValue = [],
  isValid,
  distractorRationale,
}: FeedbackData<Array<Array<number>>>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = distractorRationale || '';
  } else {
    // determine which items are incorrectly placed so that we can show the feedback for them:
    // we can't trust L8y's mapValidationMetadata's distractorRationaleResponseLevel for this,
    // because it doesn't include items placed in correct AND incorrect containers as incorrect
    const { validation: { valid_response }, metadata: { distractor_rationale_response_level } } = questionData;
    const correctAnswer = valid_response.value as Array<Array<number>>;
    const itemsToShowFeedbackFor = [];
    let anyIncorrectlyUnplaced = false;
    for (let i = 0; i < responseValue.length; i++) {
      const responseBinContents = responseValue[i];
      const correctAnswerBinContents = correctAnswer[i];
      if (!!responseBinContents) {
        for (const responseItem of responseBinContents) {
          if (!correctAnswerBinContents.includes(responseItem)) {
            itemsToShowFeedbackFor.push(responseItem);
          }
        }
      }
      if (!!correctAnswerBinContents.length && (!responseBinContents || correctAnswerBinContents.some(c => !responseBinContents.includes(c)))) {
        anyIncorrectlyUnplaced = true;
      }
    }
    const itemsToShowFeedbackForUnique = [...new Set(itemsToShowFeedbackFor)].sort();
    if (itemsToShowFeedbackForUnique.length && !!distractor_rationale_response_level) {
      // if there's feedback written for any of the incorrect items: show that
      const distractorList = itemsToShowFeedbackForUnique.reduce((acc: Array<string>, item) => {
        if (!!distractor_rationale_response_level[item]) {
          acc.push(distractor_rationale_response_level[item]);
        }
        return acc;
      }, []);

      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }

    // if all responses that are placed are placed correctly but at least 1 response is incorrectly unplaced: show generic message
    const anyIncorrect = !!itemsToShowFeedbackForUnique.length;
    if (!outputJSX && anyIncorrectlyUnplaced && !anyIncorrect) {
      outputJSX = 'There is at least one more item that belongs in a bin.';
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackMatchListQ = ({
  questionData,
  responseLevel,
  responseValue = [],
  isValid,
  distractorRationale = '',
}: FeedbackData<Array<string>>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = fromHtmlString(distractorRationale);
  } else {
    const { validation: { valid_response: { value: correctAnswer } } } = questionData;
    if (responseLevel !== false) {
      // if there's feedback written for incorrect responses: show that
      const { incorrect } = responseLevel;
      const distractorList = getIncorrectDistractorList(incorrect);
      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }
    const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c && !responseValue[i]);
    const anyIncorrect = correctAnswer.some((c, i) => !!responseValue[i] && responseValue[i] !== c);
    // if any incorrectly blank and no incorrect responses: show the generic message
    if (!outputJSX && anyIncorrectlyBlank && !anyIncorrect) {
      outputJSX = 'You have not filled in all the boxes.';
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

const renderFeedbackLabelImageDragAndDropQ = ({
  questionData,
  responseLevel,
  responseValue = [],
  isValid,
  distractorRationale = '',
}: FeedbackData<Array<Array<string>>>): FeedbackResult => {
  let outputJSX: ReactNode = '';
  let isCorrect = YesNo.No;
  if (isValid) {
    // if fully correct: show the general feedback
    isCorrect = YesNo.Yes;
    outputJSX = fromHtmlString(distractorRationale);
  } else {
    const { validation: { valid_response } } = questionData;
    const correctAnswer = valid_response.value as Array<Array<string>>;
    if (!!responseLevel) {
      // if there's feedback written for incorrect responses: show that
      const { incorrect } = responseLevel;
      const distractorList = getIncorrectDistractorList(incorrect);
      if (!!distractorList.length) {
        outputJSX = buildDistractorList(distractorList);
      }
    }
    const anyIncorrectlyBlank = correctAnswer.some((c, i) => !!c.length && !responseValue[i].length);
    const anyIncorrect = correctAnswer.some((c, i) => !!responseValue[i].length && JSON.stringify(responseValue[i].sort()) !== JSON.stringify(c.sort()));
    // if any incorrectly blank and no incorrect responses: show the generic message
    if (!outputJSX && anyIncorrectlyBlank && !anyIncorrect) {
      outputJSX = 'You have not filled in all the containers.';
    }
  }

  return {
    responseId: questionData.response_id,
    isCorrect,
    outputJSX,
  };
};

/* Top Level Render Handling */
export const distractorRationaleTemplate = (id: string, isCorrect: YesNo, content?: ReactNode) => {
  const valid = isCorrect === YesNo.No ? 'Incorrect' : 'Correct';
  const validForCss = valid.toLowerCase();
  const rationaleJsx = (
    <div id={`#${id}_distractor`} className="lrn_distractor_rationale_wrapper" role="region" aria-label="distractor rationale">
      <div className={`lrn_distractor_rationale_list lrn_distractor_rationale_${validForCss}`} aria-live={AriaLiveAttribute.Polite}>
        <div className="lrn_distractor_rationale_list_title">{valid}</div>
        {!content ? '' : (
          <div className="lrn_distractor_rationale">
            <div className="lrn_distractor_rationale_content">{content}</div>
          </div>
        )}
      </div>
    </div>
  );
  return renderToString(rationaleJsx);
};

const renderDistractor = (responseId: string, isCorrect: YesNo, content: ReactNode) => {
  // src: https://github.com/Learnosity/learnosity-demos/blob/master/www/assessment/distractor-rationale.php#L316
  if (!document.querySelector(`[id='#${responseId}_distractor']`)) {
    const template = distractorRationaleTemplate(responseId, isCorrect, content);
    const selector = document.querySelector(`[id='${responseId}']`);
    if (!!selector) {
      selector.insertAdjacentHTML('beforeend', template);
    }
  }
};

export const handleGetFeedbackOnValidate = (feedbackData: FeedbackData<LearnosityResponseValue>): FeedbackResult | null => {
  const { questionData } = feedbackData;
  switch (questionData.type) {
    case L8yQuestionType.MultipleChoiceMultipleSelect:
      if (questionData.multiple_responses) {
        return renderFeedbackMSQ(feedbackData as FeedbackData<string>);
      }
      return renderFeedbackMCQ(feedbackData as FeedbackData<string>);
    case L8yQuestionType.ClozeDropDown:
      return renderFeedbackClozeDDQ(feedbackData as FeedbackData<Array<string>>);
    case L8yQuestionType.LabelImageDropDown:
      return renderFeedbackLabelImageDDQ(feedbackData as FeedbackData<Array<string>>);
    case L8yQuestionType.Hotspot:
      return renderFeedbackHotspotQ(feedbackData as FeedbackData<Array<string>>);
    case L8yQuestionType.Sequence:
      return renderFeedbackSortListQ(feedbackData as FeedbackData<Array<number>>);
    case L8yQuestionType.OrderList:
      return renderFeedbackOrderListQ(feedbackData as FeedbackData<string>);
    case L8yQuestionType.Sorting:
      return renderFeedbackClassificationQ(feedbackData as FeedbackData<Array<Array<number>>>);
    case L8yQuestionType.MatchList:
      return renderFeedbackMatchListQ(feedbackData as FeedbackData<Array<string>>);
    case L8yQuestionType.LabelImageDragAndDrop:
      return renderFeedbackLabelImageDragAndDropQ(feedbackData as FeedbackData<Array<Array<string>>>);
    default:
      console.debug(`No custom feedback display function available for question type ${questionData.type}`);
      return null;
  }
};

export const renderFeedbackOnValidate = (feedbackData: FeedbackData<LearnosityResponseValue>) => {
  const feedback = handleGetFeedbackOnValidate(feedbackData);
  if (feedback) {
    const { responseId, isCorrect, outputJSX } = feedback;
    renderDistractor(responseId, isCorrect, outputJSX);
  }
};

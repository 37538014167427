import React from 'react';
import { useSelector } from 'react-redux';
import useDocumentTitle from 'hooks/useDocumentTitle';
import CourseList from 'shared-components/CourseList/CourseList';
import { RoleEnum } from 'types/backend/roles.types';
import { Store } from 'types/store.types';
import './CourseListController.scss';

function Home() {
  useDocumentTitle('My Courses');
  const instructorCourses = useSelector((store: Store) => store.passive.instructorCourses);
  const studentCourses = useSelector((store: Store) => store.passive.studentCourses);
  const hasInstructorCourses = !!instructorCourses?.length;
  return (
    <main className="course-list-controller">
      {hasInstructorCourses && (
        <>
          <CourseList
            linkPrefix="/instructor"
            courses={instructorCourses}
            enrollmentRole={RoleEnum.Instructor}
            showRoleHeader
          />
          <hr/>
        </>
      )}
      <CourseList
        linkPrefix="/student"
        courses={studentCourses}
        enrollmentRole={RoleEnum.Student}
        showRoleHeader={hasInstructorCourses}
      />
    </main>
  );
}

export default Home;

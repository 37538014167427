import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { AssessmentPillProps } from './BetterTimeline';
import { YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { InstructorCoursePath } from 'types/instructor.types';
import { AssessmentStatus } from 'types/common.types';

const InstructorAssessmentPill = ({
  assessType,
  classSessionId,
  courseLos,
  id: assessmentId,
  inWindow = true,
  mergedOpenDate,
  mergedDueDate,
  name: assessmentName,
  published,
  totalPoints,
  prep,
  practiceTest,
  returnToCsId,
}: AssessmentPillProps) => {
  const csIdSuffix = `&class-session=${classSessionId || returnToCsId}`; // if assessment is on a non-class day, return to prev selected csId
  const assessmentLink = `${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentId}${csIdSuffix}`;
  let publishedText: string = published === YesNo.Yes ? AssessmentStatus.Published : AssessmentStatus.Unpublished;
  if (assessType === AssessTypeEnum.Summative) {
    const prepPublished = prep?.published === YesNo.Yes;
    const practicePublished = practiceTest?.published === YesNo.Yes;
    if (prepPublished !== practicePublished) {
      const prepPublishedText = `Prep ${prepPublished ? AssessmentStatus.Published : AssessmentStatus.Unpublished}`;
      const practicePublishedText = `Practice Test ${practicePublished ? AssessmentStatus.Published : AssessmentStatus.Unpublished}`;
      publishedText = `${prepPublishedText} | ${practicePublishedText}`;
    }
  }
  return (
    <BetterTooltip
      className="better-timeline__assessment-tooltip-wrap instructor-assessment-pill"
      content={(
        <div className="better-timeline__assessment-tooltip" data-assesstype={assessType}>
          <div className="better-timeline__assessment-tooltip-name">
            {assessmentName}
          </div>
          <div className="better-timeline__assessment-tooltip-status" data-published={published}>
            {publishedText}
          </div>
          <div className="better-timeline__assessment-tooltip-body">
            <div className="better-timeline__assessment-tooltip-dates__wrap">
              {assessType !== AssessTypeEnum.Summative && (
                <>
                  <div className="better-timeline__assessment-tooltip-date">
                    <div>Total Points:</div>
                    <div>{totalPoints}</div>
                  </div>
                  <div className="better-timeline__assessment-tooltip-date">
                    <div>Open:</div>
                    <div>{DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.DateAtTimeMeridean)}</div>
                  </div>
                </>
              )}
              <div className="better-timeline__assessment-tooltip-date">
                <div>Due:</div>
                <div>{DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.DateAtTimeMeridean)}</div>
              </div>
            </div>
            <div className="better-timeline__assessment-tooltip-lo-pills">
              {courseLos.map(({ id, _derived: { loNumber } }) => (
                <span key={`${id}_${loNumber}`} className="lo-pill">{loNumber}</span>
              ))}
            </div>
          </div>
        </div>
      )}
    >
      <Link
        className="better-timeline__pill assessment-pill"
        data-assesstype={assessType}
        data-published={published}
        key={`assessment-link_${assessmentId}`}
        to={assessmentLink}
        tabIndex={inWindow ? 0 : -1}
        aria-hidden={!inWindow}
      >
        {getAssessTypeShortName(assessType)}
      </Link>
    </BetterTooltip>
  );
};

InstructorAssessmentPill.propTypes = {
  assessType: PropTypes.oneOf(Object.values(AssessTypeEnum)).isRequired,
  classSessionId: PropTypes.number,
  mergedOpenDate: PropTypes.string.isRequired,
  mergedDueDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  published: PropTypes.oneOf(Object.values(YesNo)).isRequired,
  prep: PropTypes.object,
  practiceTest: PropTypes.object,
};

export default InstructorAssessmentPill;
